import { useState, useEffect, useRef } from 'react';
import { Socket } from 'phoenix';
import { logTrace, logException } from '../services/loggerFront';

export function useVsCodeChannel(testAttemptId, devEnvNeeded, setTestStage, page) {
    const [channel, setChannel] = useState(null);
    const channelRef = useRef(null);
    const [channelMessages, setChannelMessages] = useState([]);
    const [channelError, setChannelError] = useState(null);
    const [extensionAliveReceived, setExtensionAliveReceived] = useState(false);
    const fileName = 'useVsCodeChannel';

    // log ny chnage to channel
    useEffect(() => {
        // update the ref
        channelRef.current = channel;
        logTrace('useVsCodeChannel', {channelRef: channelRef.current, testAttemptId, fileName});
    }, [channel]);
  
    useEffect(() => {
        
        // if it is not the interview, return
        if (location.pathname === "/entrevista" || location.pathname.includes('/entrevista/')) {
            return;
        }

        if (!testAttemptId || !devEnvNeeded) {
            return;
        }

        const socket = new Socket('wss://degrau-orchestrator.greenbeach-5b9d89f6.brazilsouth.azurecontainerapps.io/socket');
        socket.connect();

        const channel = socket.channel(`attempt_channel:${testAttemptId}`, {});
        channel.join()
        .receive('ok', () => {
            setChannel(channel);
            logTrace('set channel', {channelRef: channelRef.current, testAttemptId, fileName});

            // // Set a timeout to check if 'extension_alive' was received
            // const extensionAliveTimeout = setTimeout(() => {
            //     if (!extensionAliveReceived) {
            //         console.log('extension_alive not received within 5 minutes');
            //         // setTestStage('VsCodeLoadFail');
            //     }
            // }, 10 * 60 * 1000); // X (x * 60 * 1000) minutes in milliseconds
        })
        .receive('error', (err) => {
            logException('Failed to join VSCode channel', {err, testAttemptId, fileName});
            setChannelError(err);
        });

        // Listen for 'extension_alive' message
        channel.on('extension_alive', (payload) => {
            logTrace('extension_alive', {payload, testAttemptId, fileName});
            // Clear the timeout as we've received the message
            // send 'start_attempt' as channel message
            sendChannelMessage('start_attempt');
        });

        // Listen for 'final_snapshot' message
        channel.on('final_snapshot', (payload) => {
            // Add your logic to handle the final snapshot
            // For example, you might want to store it in state or process it
        });

        channel.on('new_message', (payload) => {
            setMessages((prevMessages) => [...prevMessages, payload]);
        });

        channel.on('presence_diff', (diff) => {
            setConnectedSystems(prevSystems => {
                const newSystems = [...prevSystems, ...Object.keys(diff.joins)];
                return newSystems.filter(system => !Object.keys(diff.leaves).includes(system));
            });
        });

        channel.on('presence_state', (state) => {
            setConnectedSystems(Object.keys(state));
        });

        return () => {
            if (channel) {
                channel.leave();
            }
        socket.disconnect();
    };
  }, [testAttemptId, devEnvNeeded]);

  const sendChannelMessage = (message) => {
    logTrace('sendChannelMessage', {message, channelRef: channelRef.current, testAttemptId, fileName});
    if (channelRef.current) {
      channelRef.current.push(message)
        .receive('ok', (response) => logTrace('Message sent successfully', {response, testAttemptId, fileName}))
        .receive('error', (err) => logTrace('Failed to send message', {err, testAttemptId, fileName}));
    } else {
        logException('Cannot send message: channel is not initialized', {testAttemptId, fileName});
    }
  };

  const sendFinishAttemptMessage = () => {
    logTrace('sendFinishAttemptMessage', {testAttemptId, fileName});
    return new Promise((resolve, reject) => {
        if (channelRef.current) {
            channelRef.current.push('finish_attempt')
                .receive('ok', (response) => {
                    logTrace('Finish attempt message sent successfully', {response, testAttemptId, fileName});
                    resolve(response.last_blob); // Return only the last_blob
                })
                .receive('error', (err) => {
                    logException('Failed to send finish attempt message', {err, testAttemptId, fileName});
                    reject(err);
                });
        } else {
            logException('Cannot send finish attempt message: channel is not initialized', {testAttemptId, fileName});
            reject(new Error('Channel not initialized'));
        }
    });
};

  return {
    channel,
    channelMessages,
    channelError,
    sendChannelMessage,
    sendFinishAttemptMessage,
  };
}