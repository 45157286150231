import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import { useAppContext } from './AppContext';
import { useTestContext } from './TestContext';
import { useCodeContext } from './CodeContext';
import { typeMessage } from '../utils/typeMessage';
import throttle from '../utils/throttle';
import formatDateForMySQL from '../utils/formatDateForMysql';
import { updateCodeResponse } from '../services/databaseService';
import { updateTestAttempt } from '../services/candidateTestService';
import { logEvent, logException, logTrace } from '../services/loggerFront';
import useChatInstructions from '../hooks/useChatInstructions';
import { set } from 'lodash';

export const ChatContext = createContext();

// ChatInput rules and messages //

const validationRules = {
    textarea: (input) => input.trim().length > 0,
    name: (input) => /^[a-zA-Z\s]+$/.test(input.trim()),
    email: (input) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(input.trim()),
};

const errorMessages = {
    textarea: "O texto não pode estar em branco.",
    name: "Insira pelo menos dois nomes, sem caracteres especiais, separados por um espaço.",
    email: "Insira um endereço de e-mail válido.",
    charLimitExceeded: "A mensagem não pode conter mais de 1000 caracteres."
};

const placeholderText = {
    textarea: "Escreva sua mensagem aqui",
    name: "Escreva seu nome completo",
    email: "Escreva seu email",
};

export const ChatProvider = ({ children }) => {
    // Messages and chats
    const [chats, setChats] = useState({ 0: [] }); // Store multiple chat sessions, initialize with an empty chat (session 0)
    const [currentChatId, setCurrentChatId] = useState(0); // Track the current chat session
    const currentChatIdRef = useRef(currentChatId); // Ref to track the current chat session
    const messages = chats[currentChatId] || [];
    const messagesRef = useRef(messages); // Get the most up to date messages array
    
    const { INTERRUPTION_RETRY_MAX, INTERRUPTION_RETRY_INTERVAL } = useAppContext();
    const isTestInterruptedRef = useRef({ interrupted: false, reason: null });
    const isFinalTimeUpRef = useRef(); 
    const { testStage, 
        testAttemptId, 
        candidateId, 
        codeResponseId, 
        completeIntroChat, 
        updateUserDetails, 
        updateTestStatus, 
        accessibilityMode, 
        setAccessibilityMode, 
        setAccessibilityDetails,
        retrievedSession, 
        retrievedMessages, 
        retrievedCodeSubmitted, 
        retrievedGptCodeEvaluation, 
        finalCode, 
        codeSubmitted, 
        finalConsoleOutput, 
        caseInstructions, 
        setShowProceedButton,
        currentSection, 
        completeTest, 
        isTestInterrupted, 
        setIsTestInterrupted, 
        isFinalTimeUp, 
        setStopTimer, 
        sectionTimeUp, 
        setSectionTimeUp, 
        boilerplateCode, 
        initialDatabaseSchema,
        devEnvNeeded,
        devEnvLanguage,
        devEnvFramework,
        devEnvPackageManager,
    } = useTestContext(); 
    const currentSectionRef = useRef(currentSection); // Ref to track the current section. Needed for testRetrieval
    const { currentIdeLanguage, sqlLogs, databaseSchema, ideCompilerResponse } = useCodeContext();
    // UI Management
    const [inputVisible, setInputVisible] = useState(true); // Is chatInput visible
    const [inputType, setInputType] = useState('textarea'); // The type of the chatInput
    const [inputValue, setInputValue] = useState(''); // The value of the chatInput
    const [isChatInputDisabled, setIsChatInputDisabled] = useState(true); // Is chatInput disabled
    const [isPhotoCheckVisible, setIsPhotoCheckVisible] = useState(false); // Is the photoContainer visible
    const [isScreenShareVisible, setIsScreenShareVisible] = useState(false); // Is the screenShareContainer visible
    const [showTimeUpButtons, setShowTimeUpButtons] = useState(false); // Are the time up buttons visible to allow the user to submit their last message
    const [isAccessibilityQuestionInput, setIsAccessibilityQuestionInput] = useState(false); // Are the yes no buttons visible
    const [isUndoAccessibilityButtonVisible, setIsUndoAccessibilityButtonVisible] = useState(false); // Is the undo button visible
    const [accessibilityChatInstructionIndex, setAccessibilityChatInstructionIndex] = useState(null); // The chat instruction to show after the accessibility question
    const [showLoader, setShowLoader] = useState(false)
    const [chatInputError, setChatInputError] = useState(''); // Error message to display if input is invalid
    const [userHasInteracted, setUserHasInteracted] = useState(false); // Flag to track if the user has interacted with the input
    const [isHoveringSend, setIsHoveringSend] = useState(false) // Flag to track if the user is hovering over the send button
    // Instructions and chat management
    const [currentChatInstruction, setCurrentChatInstruction] = useState(null); // Used to decide next actions, e.g. if the user is in a gotConversation
    const [chatInstructionsSaved, setChatInstructionsSaved] = useState(false);
    const [ isGptStreaming, setIsGptStreaming ] = useState(false); // Is GPT currently writing to the user (not used)
    const isGptStreamingRef = useRef(); 
    const [userInputCount, setUserInputCount] = useState(0);
    const [testAttemptSaveFailedCount, setTestAttemptSaveFailedCount] = useState(0);
    const [isGptResponseDelayed, setIsGptResponseDelayed] = useState(false); // State to notify user if there is a delay in the gpt call (currently an error message under ChatInput). Called by handleGptConversation and acted upon in ChatInput
    let instructionsTextRef = useRef(''); // Get the instructions for the current section
    let preEvalCriteriaRef = useRef(''); // Ref to store the pre-evaluation criteria for the code
    const [ sectionMovedOn, setSectionMovedOn ] = useState(false);
    // Coding
    const [codeSubmissionHandled, setCodeSubmissionHandled] = useState(false);
    const [gptCodeEvaluation, setGptCodeEvaluation] = useState(null); // The preliinary evaluation of the code by GPT to guide the follow-up questions
    const [devEnvConcat, setDevEnvConcat] = useState(null);
    const codeLanguage = devEnvConcat ? devEnvConcat : currentIdeLanguage.key;
    // Test retrieval
    const [retrievedSessionChatIdApplied, setRetrievedSessionChatIdApplied] = useState(false); // Flag to track if the retrieved session chatId has been applied
    
    const [ gptConnectionAttempts, setGptConnectionAttempts ] = useState(0); // Number of attempts to connect to GPT
    const [countdown, setCountdown] = useState(15); // Show user we are retrying when there is connection error. 15 seconds for each attempt

    const fileName = 'ChatContext.js'; // For logging purposes

    // CODE LANGUAGES
    useEffect(() => {
        if (devEnvLanguage && devEnvFramework && devEnvPackageManager) {
            const concat = `${devEnvLanguage.name}_${devEnvFramework.name}_${devEnvPackageManager.name}`;
            setDevEnvConcat(concat);
        }
    }, [devEnvLanguage, devEnvFramework, devEnvPackageManager]);

    // CALLBACKS

    // Register the scrollToBottom callback
    const registerScrollToBottomCallback = (callback) => {
        scrollToBottomCallbackRef.current = throttle(callback, 1000); // Throttle the callback (scrollToBottom) to run at most once every second
    };

    const triggerScrollToBottom = () => {
        if (scrollToBottomCallbackRef.current) { // Check if the callback is defined (it should be the scrollToBottom function)
            scrollToBottomCallbackRef.current(); // Call the callback to scroll to the bottom
        }
    };

    // REFS

    const scrollToBottomCallbackRef = useRef(null); // Callback to scroll to the bottom of the chatbox

    // Update isTestInterrupted to prevent extra database saves when a failed one is currently looping
    useEffect(() => {
        isTestInterruptedRef.current = isTestInterrupted;
    }, [isTestInterrupted]); 

    // Latest ref for istime up
    useEffect(() => {
        isFinalTimeUpRef.current = isFinalTimeUp;
    }, [isFinalTimeUp]); 
    
    // Get the latest ref for isgptstreaming (DELETE?)
    useEffect(() => {
        isGptStreamingRef.current = isGptStreaming;
    }, [isGptStreaming]);

    // Update ref whenever messages changes
    useEffect(() => {
        messagesRef.current = messages;
    }, [messages]);

    // Update the chatid ref
    useEffect(() => {
        currentChatIdRef.current = currentChatId;
    }, [currentChatId]);

    // Update the current section ref (needed for testRetrieval)
    useEffect(() => {
        currentSectionRef.current = currentSection;
    }, [currentSection]);

    // LOGGING //

    // Log change in chat section and reset the userInputCount
    useEffect(() => {
        if (testStage === 'test') { // Only log if testStage is 'test'
            logEvent(`Chat section moved', {currentSection.sectionName}`, { 
                section: currentSection.sectionName,
                testAttemptId: testAttemptId,
            });
            setUserInputCount(0); // Reset the user input count when the section changes
        }
    }, [currentSection]); // Include testStage in the dependency array

    // TEST RETRIEVAL //

    // Update codeSubmitted if a session is restored (AppContext will update retrievedMessages with the messages saved in the DB for that session)
    useEffect(() => {
        setCodeSubmissionHandled(retrievedCodeSubmitted);
    }, [retrievedCodeSubmitted]);

    // Update gptCodeEvaluation with the evaluation from the session being restored. AppContext updates retrievedGptCodeEvaluation
    useEffect(() => {
        if (retrievedGptCodeEvaluation) {
            setGptCodeEvaluation(retrievedGptCodeEvaluation); 
        }
    }, [retrievedGptCodeEvaluation]);

    // Add the retrieved messages to the chats and chatbox will show when it gets to testStage test
    useEffect(() => {
        if (testStage === 'test' && retrievedMessages && retrievedMessages.length > 0) {
            setChats(prevChats => {
                const nextIndex = Object.keys(prevChats).length;
                return { ...prevChats, 1: retrievedMessages };
            });
        }
    }, [retrievedMessages, testStage, setChats]);

    // SAVING TO DB //

    // Save test chat transcript, move to error hanlding state which just unique link to reload test if it fails
    const RETRY_LIMIT_FOR_CHAT = 1; // Maximum number of retries for a single save attempt
    const MAX_FAILED_SAVES_FOR_CHAT = 2; // Maximum allowed cumulative failed save attempts

    // Update testattempt with the lastest transcript. Called when a new message is completed or when X seconds are passed
    const saveTestChatTranscript = () => {
        // Dont proceed with the function if we are in testInterrupted mode
        if (isTestInterruptedRef.current.interrupted) {
            return;
        }

        const updateData = {
            test_chat_transcript: messagesRef.current,
        };
    
        const performSave = async (retryCount = 0, afterInterruptionRetryCount = 0) => {
            try {
                await updateTestAttempt(testAttemptId, updateData, false);
                // If it works reset counters and ensure we exit the test interrupted state
                setTestAttemptSaveFailedCount(0);
                setIsTestInterrupted({ interrupted: false, reason: null }); // Reset on successful save
            } catch (error) {
                // If it doesnt work, check if we should retry again
                if (retryCount < RETRY_LIMIT_FOR_CHAT) {
                    // Immediate retry with a short delay
                    setTimeout(() => performSave(retryCount + 1, afterInterruptionRetryCount), 5000);
                } else {
                    // Increment failure count and check for extended retries
                    setTestAttemptSaveFailedCount((prevCount) => {
                        const newCount = prevCount + 1;
                        if (newCount >= MAX_FAILED_SAVES_FOR_CHAT && !isTestInterruptedRef.current.interrupted) {
                            setIsTestInterrupted({ interrupted: true, reason: 'testattempt database chatbox' }); // Mark as interrupted
                            setTimeout(() => performSave(RETRY_LIMIT_FOR_CHAT + 1, afterInterruptionRetryCount + 1), INTERRUPTION_RETRY_INTERVAL * 1000);
                        }
                        // Trigger the extended retry logic if within limits and test is interrupted
                        if (isTestInterruptedRef.current.interrupted && afterInterruptionRetryCount < INTERRUPTION_RETRY_MAX) {
                            setTimeout(() => performSave(retryCount, afterInterruptionRetryCount + 1), INTERRUPTION_RETRY_INTERVAL * 1000);
                        }
                        return newCount;
                    });
                }
            }
        };
        performSave();
    };
    
    // Save test chat instructions after they are loaded for record keeping
    const saveTestChatInstructions = (chatInstructions) => {
        const updateData = {
            test_chat_instructions: JSON.stringify(chatInstructions),
        };
        if (testStage === 'test' && !chatInstructionsSaved) {
            updateTestAttempt(testAttemptId, updateData, false);
            setChatInstructionsSaved(true); // Set flag to true so we dont save again
        }
    };

    // MOVE TO NEXT CHAT //

    // For retrieved sessions move to chat id 1 to access the retrieved messages
    useEffect(() => {
        if (retrievedSession && testStage === 'test' && !retrievedSessionChatIdApplied) { // Check if the session was retrieved and the chatId has not been applied
            if (currentChatId === 0) { // check if the currentChatId is 0
                setCurrentChatId(1); // Move to 1 to access the retrieved messages (0 is the introChat)
                setChats(prevChats => {
                    const nextIndex = Object.keys(prevChats).length;
                    return { ...prevChats, [nextIndex]: retrievedMessages };
                });
                setRetrievedSessionChatIdApplied(true); // Set the flag to true so we dont apply the chatId again
            }
        }
    }, [testStage]);

    // Move to next chat session when the chat section has a startNew flag
    useEffect(() => {
        if (currentSection && currentSection.chat && currentSection.chat.startNew) { // Check if the chat section has a startNew flag
            // IF it is a retrieved session and retrievedSessionChatIdApplied is false, return
            if (retrievedSession && !retrievedSessionChatIdApplied) {
                return;
            }
            setCurrentChatId(prevChatId => prevChatId + 1); // Move to the next chat session to have a separate set of messages
        }
    }, [currentSection]);
    
    // ADDING MESSAGES //

    // Add a message to the chat array (and therefore UI and transcript)
    const addMessage = (message) => {
        setChats(prevChats => {
            const updatedMessages = [...(prevChats[currentChatId] || []), message];
            return { ...prevChats, [currentChatId]: updatedMessages };
        });
        saveTestChatTranscript();
    };

    // Add the message before the timeUp message to have a coherent chat flow (as the user can submit the last answer after the time is up)
    const addMessageWithTimeUpCheck = (newMessage) => {
        setChats((prevChats) => {
            const updatedChats = { ...prevChats };
            const messages = updatedChats[currentChatId] || []; // Get the current chat messages
            // Find the index of the "time up" message
            const timeUpMessageIndex = messages.findIndex(m => m.section === "timeUp");

            if (timeUpMessageIndex >= 0) {
                // If the "time up" message is found, insert the new user message before it
                updatedChats[currentChatId] = [
                    ...messages.slice(0, timeUpMessageIndex),
                    newMessage,
                    ...messages.slice(timeUpMessageIndex),
                ];
            } else {
                // If no "time up" message is present, add the new user message to the end
                updatedChats[currentChatId] = [...messages, newMessage];
            }
            return updatedChats;
        });
    };

    // SYSTEM MESSAGES //

    // Add a non-GPT system message to the array (and therefore UI and transcript), call for it to be typed and save the updated transcript. processChatInstruction will deal with the next action
    const addSystemMessage = (chatInstruction) => {
        setCurrentChatInstruction(chatInstruction);
        setIsChatInputDisabled(true);
    
        return new Promise((resolve, reject) => {
            setChats(prevChats => {
                const currentChatId = currentChatIdRef.current;
                const currentMessages = prevChats[currentChatId] || [];
    
                const newMessageId = `systemMessage${currentMessages.length}`;
                const timestamp = formatDateForMySQL(new Date());
                const messageSection = (currentSectionRef.current && currentSectionRef.current.sectionName) || 'unknown';
    
                // Check if the message with the same id already exists
                if (currentMessages.some(msg => msg.id === newMessageId)) {
                    return prevChats; // Return previous state to prevent duplicate addition
                }
    
                // Create the new message
                const newMessage = {
                    ...chatInstruction,
                    id: newMessageId,
                    type: 'system',
                    gpt: 'no',
                    instructionType: chatInstruction.type,
                    section: messageSection,
                    isFirstMessage: currentMessages.length === 0,
                    instructionsId: chatInstruction.id,
                    timestamp: timestamp,
                    content: "",
                    fullText: chatInstruction.content,
                    isVisible: true,
                };
    
                // Append the new message
                const updatedMessages = [...currentMessages, newMessage];
                const updatedChats = { ...prevChats, [currentChatId]: updatedMessages };
    
                // Scroll to bottom after updating messages
                triggerScrollToBottom();
    
                return updatedChats;
            });
    
            setTimeout(() => {
                setChats(prevChats => {
                    const currentChatId = currentChatIdRef.current;
                    const currentMessages = prevChats[currentChatId] || [];
                    const index = currentMessages.findIndex(m => m.id === `systemMessage${currentMessages.length - 1}`);
    
                    if (index !== -1) {
                        // Start typing the message content
                        typeMessage({...currentMessages[index], content: currentMessages[index].fullText}, index, setChats, currentChatId)
                            .then(resolve)
                            .catch(reject);
    
                        // Scroll to bottom after updating messages
                        triggerScrollToBottom();
                    } else {
                        reject(new Error("Message to type was not found."));
                    }
                    return prevChats;
                });
            }, 0);
    
            // Save the transcript when the message is complete, except in intro chat
            if (testStage === 'test') {
                saveTestChatTranscript();
            }
        });
    };
    
    const currentGptMessageIdRef = useRef(null);

    // As per addSystemMessage but for GPT streaming messages
    const addGptSystemMessage = (content, isNewMessage, isEndOfStream, chatInstruction, deleteCurrentMessage = false, finalPrompt, runId = null, skipToNextTriggerMet = false, isVisible = true, fullGptMessage ) => {
        setCurrentChatInstruction(chatInstruction);
        if (isVisible) {
            setIsChatInputDisabled(true);
            setIsGptStreaming(true);
        }
    
        return new Promise((resolve, reject) => {
            const messages = chats[currentChatId]; // Get the current chat
            const newMessageId = `systemMessage${messagesRef.current.length}`;
            const timestamp = formatDateForMySQL(new Date());
            const messageSection = currentSectionRef.current.sectionName;
            const newMessage = {
                id: newMessageId,
                type: 'system',
                gpt: 'yes',
                instructionType: chatInstruction.type,
                content: content.content || "",
                fullGptMessage: fullGptMessage || "",
                section: messageSection || 'missing',
                finalPrompt: finalPrompt,
                instructionsId: chatInstruction.id,
                timestamp: timestamp,
                runId,
                skipToNextTriggerMet: skipToNextTriggerMet,
                isStreamingComplete: isEndOfStream,
                isVisible: isVisible,
            };
    
            if (deleteCurrentMessage) {
                setChats(prevChats => {
                    const updatedChats = { ...prevChats };
                    const updatedMessages = updatedChats[currentChatId] && updatedChats[currentChatId].messages 
                        ? updatedChats[currentChatId].messages.filter(msg => msg.id !== currentGptMessageIdRef.current) 
                        : [];
                    updatedChats[currentChatId] = { messages: updatedMessages };
                    return updatedChats;
                });
                currentGptMessageIdRef.current = newMessageId;
                addMessage(newMessage);
                triggerScrollToBottom();
            } else if (isNewMessage || !currentGptMessageIdRef.current) {
                currentGptMessageIdRef.current = newMessageId;
                addMessage(newMessage);
                triggerScrollToBottom();
            } else {
                setChats(prevChats => {
                    const updatedChats = { ...prevChats };
                    const currentMessages = Array.isArray(updatedChats[currentChatId]) ? updatedChats[currentChatId] : [];
                    const updatedMessages = currentMessages.map(msg => {
                        if (msg.id === currentGptMessageIdRef.current) {
                            let newContent = typeof msg.content === 'string' ? msg.content : '';
                            if (typeof content.content === 'object' && content.content.message) {
                                newContent += content.content.message;
                            } else if (typeof content.content === 'string') {
                                newContent += content.content;
                            }
                            return { ...msg, content: newContent, fullGptMessage, finalPrompt, isStreamingComplete: isEndOfStream, runId, skipToNextTriggerMet };
                        }
                        return msg;
                    });
                    updatedChats[currentChatId] = updatedMessages;
                    return updatedChats;
                });
                triggerScrollToBottom();
            }
    
            if (isEndOfStream) {
                currentGptMessageIdRef.current = null;
                setIsGptStreaming(false);
            }
    
            resolve();
        });
    };

    // Add instructions as a system message (no need to be typed)
    const addInstructionsMessage = () => { 
        setChats(prevChats => {
            const updatedChats = { ...prevChats };
            const currentChatId = currentChatIdRef.current; // Use the currentChatId from the ref
    
            // Ensure the chat for currentChatId is initialized
            if (!Array.isArray(updatedChats[currentChatId])) {
                updatedChats[currentChatId] = []; // Initialize as an empty array if not present
            }
            const currentMessages = updatedChats[currentChatId];
    
            const newMessageId = `systemMessage${currentMessages.length}`; // Ensure unique ID
            const timestamp = formatDateForMySQL(new Date()); // Current timestamp
    
            // Create the instructions message
            const newMessage = {
                id: newMessageId,
                type: 'system',
                gpt: 'no',
                instructionType: 'instructions',
                section: 'instructions',
                isFirstMessage: false,
                instructionsId: 0,
                timestamp: timestamp,
                content: instructionsTextRef.current,
                isVisible: true,
            };
    
            // Append the new instructions message to the current chat's messages
            updatedChats[currentChatId] = [...currentMessages, newMessage];
    
            return updatedChats; // Return the updated chat state
        });
    };    
    
    //USER INPUT //

    // Decide which UI elements to render based on the nextAction. Managed in processChatInstruction
    const prepareNextAction = (nextAction) => {
        
        if (isFinalTimeUpRef.current) {
            return;
        }

        //save the current transcript
        if (testStage === 'test') {
            saveTestChatTranscript();
        }

        setIsChatInputDisabled(false);  // Enable the ChatInput
        switch (nextAction) {
            case 'nameInput':
                setInputVisible(true);
                setInputType(nextAction.replace('Input', ''));
                break;
            case 'emailInput':
                setInputVisible(true);
                setInputType(nextAction.replace('Input', ''));
                setIsPhotoCheckVisible(false); // Hide photo container
                break;
            case 'textInput':
                setInputVisible(true);
                setInputType(nextAction.replace('Input', ''));
                setIsPhotoCheckVisible(false); // Hide photo container
                break;
            case 'photoInput':
                setInputVisible(false);
                setIsPhotoCheckVisible(true); // Show photo container
                triggerScrollToBottom();
                break;
            case 'screenShareInput':
                setInputVisible(false); // Hide chat input
                setIsScreenShareVisible(true); // Show screen share component
                // Scroll to bottom after making it visible
                triggerScrollToBottom();
                break;
            case 'radioButtonInput':
                setInputVisible(false);
                setIsAccessibilityQuestionInput(true); // Show radio button input
                triggerScrollToBottom();
                break;
            case 'accessibilityDetailsInput':
                setInputVisible(true);
                setInputType(nextAction.replace('Input', ''));
                setIsAccessibilityQuestionInput(false); // Hide choice buttons
                setIsUndoAccessibilityButtonVisible(true); // Show the undo button
                break;
            default:
                setInputVisible(true);
                setInputType('textarea'); // Default input type
                setIsPhotoCheckVisible(false); // Hide photo container
                setIsScreenShareVisible(false);
                setIsUndoAccessibilityButtonVisible(false);
        }
    };

    // When user inputs (mostly typing in chatInput), construct a message for the chatbox with details to be saved to the transcript and move to the next chat instruction (or loop if we are in GptConversation), managed by processChatInstruction
    const handleUserInput = (input, type = 'text', isVisible = true) => {
        setIsChatInputDisabled(true); // Disable the input so user can't send another message until we are ready

        const messages = chats[currentChatId]; // Get the current chat

        // Create the ID using the 'userMessage' prefix and the index
        const nextMessageIndex = messages.length;
        const newUserMessageId = `userMessage${nextMessageIndex}`;
        const section = (currentSection && currentSection.sectionName) || 'unknown';

        // Get the instruction id so we know where in the instructions we are
        const instructionsId = type === 'codeSubmission' ? 4 : (messages.length > 0 ? messages[messages.length - 1].instructionsId : '');
        const timestamp = formatDateForMySQL(new Date());

        // Create the new user message object
        const newUserMessage = { 
            id: newUserMessageId, 
            type: 'user', 
            content: input, 
            section: section,
            isCodeSubmission: type === 'codeSubmission',
            codeLanguage: type === 'codeSubmission' ? codeLanguage : undefined,
            instructionsId: instructionsId,
            userInputCount: userInputCount,
            timestamp: timestamp,
            isVisible: isVisible,
        };

        // Add the new message with time-up check
        addMessageWithTimeUpCheck(newUserMessage);

        logTrace(`User messaged in Chatbox ${section}`, { 
            messageText: input,
            testAttemptId: testAttemptId,
            messageDetails: newUserMessage,
        });

        // Increment the user input count   
        const updatedChats = { ...chats, [currentChatId]: [...messages, newUserMessage] };

        // Save the transcript when the message is complete, except in intro chat
        if (testStage === 'test') {
            saveTestChatTranscript();
        }

        // Scroll to bottom after updating messages
        triggerScrollToBottom();

        if (isFinalTimeUp) {
            return;
        }

        let gptCodeEvaluationIndex = 0;
        // Check which index in the instructions is the instruction with .type === 'gptCodeEvaluation'
        if (testStage === 'test') {
            gptCodeEvaluationIndex = chatInstructions[currentInstructionKey].findIndex(instruction => instruction.type === 'gptCodeEvaluation');
        } 

        let nextInstructionIndex = currentChatInstructionIndex;
        // if it is the accessibility question, and a yes, save the index so we can revert
        if (type === 'accessibility' && input === 'Sim') {
            setAccessibilityChatInstructionIndex(currentChatInstructionIndex - 1);
        }
        let nextUserInputCount = userInputCount;

        if (type === 'codeSubmission') {
            setUserInputCount(0); // Reset the user input count to 0 on code submission
            nextInstructionIndex = gptCodeEvaluationIndex - 1;
            nextUserInputCount = 0;
        } else if (currentChatInstruction && currentChatInstruction.type === 'gptConversation') {
            // Increment and proceed with the same GPT conversation (to loop - processChatInstructions will check if the limit is reached)
            setUserInputCount(prevCount => prevCount + 1);
            nextInstructionIndex = currentChatInstructionIndex - 1;
            nextUserInputCount = userInputCount + 1;
        } else if (type === 'accessibility' && input === 'Não') {
            // Increment and proceed with the same GPT conversation (to loop - processChatInstructions will check if the limit is reached)
            setUserInputCount(prevCount => prevCount + 1);
            nextInstructionIndex = currentChatInstructionIndex + 2; // skip the accessibility instructions
            nextUserInputCount = userInputCount + 1;
        } else {
            // Increment and proceed with the next chat instruction
            setUserInputCount(prevCount => prevCount + 1);
            nextInstructionIndex = currentChatInstructionIndex;
            nextUserInputCount = userInputCount + 1;
        }

        fetchNextChatInstruction(nextInstructionIndex, chatInstructions, currentInstructionKey, nextUserInputCount, updatedChats, setChats);
    };

    // When proceed button is pressed (or X seconds passes after onChatInstructionsComplete is called), trigger the AppContext functions to either move on from introChat or finish the test
    const handleProceedClick = () => {
        if (testStage === 'introChat') {
            // Go to full screen
            if (!document.fullscreenElement) {
                // If there's no element in full-screen mode, enter full-screen mode
                document.documentElement.requestFullscreen().catch((err) => {
                    logException(`Error attempting to enable full-screen mode`) , { 
                        errorName: err.name,
                        errorMessage: err.message,
                        testAttemptId: testAttemptId,
                    }
                });
                setUserHasInteracted(false); // Reset the user interaction flag
            }
            // Pass the current state of messages and chatInstructions
            completeIntroChat(messagesRef.current, chatInstructions); // Calls function in TestContext to move to next section
            setShowProceedButton(false); // Hide the proceed button
        } else if (testStage === 'test') {
            saveTestChatTranscript(); // Save the transcript
            completeTest(); // Calls function in TestContext to move to endOfTest stage, rendering the popup to move to the feedback page
        }
    };

    // When the user responds to the accessbility question, setAccessibilityMode, add the response to the chatbox and move to the next instruction
    const handleAccessibilityResponse = (response) => {
        setIsAccessibilityQuestionInput(false); // Hide the radio button input
        setIsUndoAccessibilityButtonVisible(false); // Hide the undo button
        const accessibilityMode = response === 'Sim'; // Convert response to boolean flag
        setAccessibilityMode(accessibilityMode); // Set the accessibility mode in AppContext
        handleUserInput(response, 'accessibility'); // Add the response to the chatbox, the logic there will decide which next instruction to fetch
        logTrace(`User responded ${response} to accessibility question`, {
            response,
            testAttemptId: testAttemptId,
            fileName
        });
    };

    // When the user clicks the undo button, remove the accessibility response from the chatbox and move to the previous instruction
    const handleUndoAccessibilityResponse = () => {
        setIsUndoAccessibilityButtonVisible(false); // Hide the undo button
        setAccessibilityMode(false); // Reset the accessibility mode in AppContext
        fetchNextChatInstruction(accessibilityChatInstructionIndex, chatInstructions, currentInstructionKey, userInputCount, chats, setChats)
    };
        
    // Hide the PhotoContainer
    const hidePhotoContainer = () => {
        setIsPhotoCheckVisible(false);
    };

    // Hide the ScreenShareContainer
    const hideScreenShareContainer = () => {
        setIsScreenShareVisible(false);
    };

    // CHATINPUT //

    const inputRef = React.createRef(); // Ref to the chatInput element to reset the height

    // When the user types in the chatInput, update the input value and adjust the height of the input box
    const handleInputChange = (e, inputType) => {
        const newValue = e.target.value;
        // Adjust the height based on the content
        const target = e.target;
        target.style.height = 'auto';
        const scrollHeight = target.scrollHeight;
        target.style.height = `${scrollHeight}px`;

        if (newValue.length <= 2000) {
            setInputValue(newValue);
            setChatInputError('');
            if (!userHasInteracted) setUserHasInteracted(true);
        } else {
            setChatInputError(errorMessages.charLimitExceeded);
        }
    };

    // When the user presses send in the chatInput, validate the input and call handleUserInput to process it
    const handleSubmit = (inputType) => {
        const validateInput = validationRules[inputType] || validationRules.textarea; // Get the validation rule for the input type
        const isValid = validateInput(inputValue); // Check if the input is valid
        setIsHoveringSend(false); // Reset the hover state

        if (isValid) {
            handleUserInput(inputValue); // Call the handleUserInput function with the input value
            setInputValue(''); // Clear the input
            setChatInputError(''); // Clear any error message

            // Reset the height of the input element
            if (inputRef.current) {
                inputRef.current.style.height = 'auto';
            }

            if (inputType === 'name' || inputType === 'email') {
                const userDetailsKey = inputType === 'name' ? 'username' : 'email';
                updateUserDetails({ [userDetailsKey]: inputValue.trim() }); // Update the user details in TestContext
            } else if (inputType === 'accessibilityDetails') {
                setIsUndoAccessibilityButtonVisible(false);
                setAccessibilityDetails(inputValue); // Set the accessibility details in TestContext
            }
        } else {
            setChatInputError(errorMessages[inputType] || errorMessages.textarea); // Set the error message if the input is invalid
        }
    };

    // CODING //

    // When code is submitted in the IDE, add code as message in chatbox, using handleUserInput (this call the move to the next instruction). Then calculate the remaining time to divide among the followup sections
    useEffect(() => {
        if (codeSubmitted && finalCode && !codeSubmissionHandled && !retrievedCodeSubmitted) {
            setCodeSubmissionHandled(true); // Set the flag to true after handling submission
            addInstructionsMessage(); // Add the instructions message to the chat
            const isVisible = devEnvNeeded ? false : true; // If devEnvNeeded is true, the code is not visible in the chat as we use an iframe to show the code
            handleUserInput(finalCode, 'codeSubmission', isVisible); // Add the code as a message in the chat
            updateTestStatus('followUp') // Update testAttempt entry to 'followUp'
        }
    }, [codeSubmitted, finalCode]);

    // Reset the code submission handled flag when codeSubmitted changes to false
    useEffect(() => {
        if (!codeSubmitted) {
            setCodeSubmissionHandled(false);
        }
    }, [codeSubmitted]);

    // When setGptCodeEvaluation is triggered, save GPTs evaluation of the code to the database and add as a message in the chatbox
    useEffect(() => {
        if (gptCodeEvaluation && codeResponseId) {
            // Prepare the data to update the code response
            const updateData = {
                end_time: formatDateForMySQL(new Date()),
                final_code: finalCode,
                code_language: codeLanguage,
                gpt_code_evaluation: gptCodeEvaluation,
                sql_logs: sqlLogs,
                db_schema: databaseSchema,
                console_output: ideCompilerResponse,
            };
    
            updateCodeResponse(codeResponseId, updateData)
                .then(() => {
                })
                .catch(error => {
                    logException(`Error updating code response with GPT evaluation`, { 
                        errorName: error.name,
                        errorMessage: error.message,
                        testAttemptId: testAttemptId,
                        fileName,
                        testAttemptId,
                        codeResponseId
                    });
                });
        }
    }, [gptCodeEvaluation]); 

    // When the finalConsoleOutput updates, add to the codeSubmission message
    useEffect(() => {
        if (finalConsoleOutput) {
            // Find the code submission message and update it with GPT details
            setChats(prevChats => {
                const updatedMessages = (prevChats[currentChatId] || []).map(msg => {
                    if (msg.isCodeSubmission) {
                        return {
                            ...msg,
                            finalConsoleOutput: finalConsoleOutput
                        };
                    }
                    return msg;
                });

                return { ...prevChats, [currentChatId]: updatedMessages };
            });
        }
    }, [finalConsoleOutput]);

    // TIMERS AND COUNTERS //

    // If the sections runs out of time, send the message in the box and move on (called in handleUserInput)
    useEffect(() => {
        // if sectiontimeup or finaltimeup is true and the section has not moved on
        if ( (sectionTimeUp || isFinalTimeUp) && !sectionMovedOn) {
            if (currentSection.time.onTimeUp.submitChat) {
                handleUserInput(inputValue); // Send whatever is in the box, this will trigger a call of the next instruction
            }
            // Clear the chat
            if (currentSection.time.onTimeUp.clearChat) {
                setInputValue(''); // Clear the input
                setChatInputError(''); // Clear any error message
            }
            // Set the flags to true and false to prevent multiple calls
            setSectionMovedOn(true); // Set the flag for this useEffect to true
            setTimeout(() => {
                setSectionTimeUp(false); // Reset the flag (no need to reset isFinalTimeUp)
                setSectionMovedOn(false); // Reset the flag
            }, 3000);
        }
    }, [sectionTimeUp, isFinalTimeUp]);

    // If there is a GPT response delay, show an error message and countdown to the next attempt in the ChatInput
    useEffect(() => {
        if (isGptResponseDelayed && gptConnectionAttempts < 4) {
            // Initiate an interval that updates every second
            const intervalId = setInterval(() => {
                setCountdown(prevCountdown => {
                    const newCountdown = prevCountdown - 1;
    
                    // Update the error message to reflect the new countdown, but only if we're not at the point of incrementing attempts
                    if (newCountdown > 0) {
                        setChatInputError(`Há um problema com a conexão. Tentaremos novamente em ${newCountdown}s (${gptConnectionAttempts + 1}/4).`);
                    }
                    return newCountdown;
                });
            }, 1000);
    
            // Schedule the attempt increment and countdown reset to occur after 15 seconds
            const timeoutId = setTimeout(() => {
                setGptConnectionAttempts(currentAttempts => {
                    // Ensure we only increment attempts and reset countdown if under the attempt limit
                    if (currentAttempts < 4) {
                        const newAttempts = currentAttempts + 1;
                        // After increment, immediately update the error message for the new attempt cycle
                        setChatInputError(`Há um problema com a conexão. Tentaremos novamente em 15s (${newAttempts}/4).`);
                        return newAttempts;
                    }
                    return currentAttempts;
                });
                setCountdown(15); // Reset countdown for the new attempt
            }, 15000); // This aligns with the countdown reaching 0
    
            // Cleanup function to clear the interval and timeout on component unmount or when conditions change
            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        } else {
            // When not delayed or max attempts reached, reset the state and clear any error messages
            setCountdown(15);
            setGptConnectionAttempts(0);
            setChatInputError('');
        }
    }, [isGptResponseDelayed, gptConnectionAttempts]); // Rerun effect when isGptResponseDelayed    

    // END OF TEST || INSTRUCTIONS //

    // When we have gotten to the last chat instruction show the user the proceed button (start or finish test)
    const onChatInstructionsComplete = () => {
        setInputVisible(false); // Hide the chat input
        setShowProceedButton(true); // Show the proceed button for the user to move on

        if (testStage === 'test') {
            setStopTimer(true); // Stop the main timer
            updateTestStatus('complete'); // Update the testattempt entry
            saveTestChatTranscript(); // Save the final transcript

            // Set up a delayed call to handleProceedClick after X seconds
            setTimeout(() => {
                handleProceedClick();
            }, 30000); // 30 seconds delay

            logTrace(`User has reached the end of the test`, { 
                testAttemptId: testAttemptId,
                fileName: fileName,
            });
        }
    };

    // Run closing actions if timer runs out
    useEffect(() => {
        if (isFinalTimeUp && testStage === 'test') {
            setIsChatInputDisabled(true); // Disable the input
            setStopTimer(true); // Stop the main timer
            updateTestStatus('complete'); // Update the testattempt entry

            logTrace(`User has ran out of time answering the last section`, { 
                testAttemptId: testAttemptId,
                fileName: fileName,
            });

            const timeUpMessageContent = "Obrigado por realizar nosso teste. O tempo permitido terminou. Pressione o botão 'Finalizar Teste' para enviar seu teste.";

            // Check if there is unsent user input, send it if so
            if (inputValue.trim() !== '') {
                handleUserInput(inputValue); // Send the message
            }

            setInputVisible(false); // Hide input as it is empty
            setTimeout(() => setShowProceedButton(true), 2000); // Just show the finish button after the system has written
            // If no response in X seconds, move on
            setTimeout(() => {
                handleProceedClick(); // Auto close after x seconds
            }, 30000);

            // The details of the auto system message to be added to the messages array
            const timeUpMessage = {
                content: timeUpMessageContent,
                section: "timeUp",
                type: "fixedMessage",
            };

            // Add a delay to allow for code submission if still coding
            setTimeout(() => {
                addSystemMessage(timeUpMessage); // Send the system a message in the chat
            }, 500);

            // Save the transcript after
            setTimeout(() => {
                saveTestChatTranscript(); 
            }, 3000);
        }
    }, [isFinalTimeUp, testStage]); 

    // Enable service files to check if time is up
    const isTimeUpCallback = () => isFinalTimeUpRef.current;
    
    // INSTRUCTIONS //
        
    // Update instructionsText when the current section changes
    useEffect(() => {
        if (caseInstructions && currentSection && caseInstructions[currentSection.sectionName]) {
            instructionsTextRef.current = caseInstructions[currentSection.sectionName].instructions || '';
            preEvalCriteriaRef.current = caseInstructions[currentSection.sectionName].preEvalCriteria || '';
        } else {
            // Keep the last sections
        }
    }, [caseInstructions, currentSection]);

    // NOTE: Keep at the end as the functions that are passed need to be defined first
    // Call useChatInstructions to fetch the instructions and call for them to be processed (using processChatInstructions). Deconstruct fetchNextChatInstruction and the chatInstructions themselves so the first function can be called and the later can be saved in the db
    const { chatInstructions, currentChatInstructionIndex, currentInstructionKey, fetchNextChatInstruction } = useChatInstructions(
        onChatInstructionsComplete,
        finalCode,
        boilerplateCode,
        initialDatabaseSchema,
        databaseSchema,
        saveTestChatTranscript,
        codeLanguage,
        setIsGptResponseDelayed,
        isTimeUpCallback,
        saveTestChatInstructions,
        addSystemMessage, 
        addGptSystemMessage, 
        prepareNextAction, 
        chats, 
        setChats, 
        userInputCount, 
        gptCodeEvaluation, 
        setGptCodeEvaluation,
        instructionsTextRef.current,
        preEvalCriteriaRef.current,
        currentChatId,
        accessibilityMode,
    );

    return (
        <ChatContext.Provider value={{
            messages,
            chats,
            setChats,
            currentChatId,
            inputValue,
            setInputValue,
            inputVisible,
            inputType,
            isChatInputDisabled,
            isPhotoCheckVisible,
            isScreenShareVisible,
            showTimeUpButtons,
            isAccessibilityQuestionInput,
            isUndoAccessibilityButtonVisible,
            showLoader,
            hidePhotoContainer,
            hideScreenShareContainer,
            currentChatInstruction,
            chatInstructionsSaved,
            isGptStreaming,
            userInputCount,
            sectionMovedOn,
            codeSubmissionHandled,
            addSystemMessage,
            addGptSystemMessage,
            addInstructionsMessage,
            handleUserInput,
            handleAccessibilityResponse,
            handleUndoAccessibilityResponse,
            saveTestChatTranscript,
            prepareNextAction,
            handleProceedClick,
            registerScrollToBottomCallback,
            onChatInstructionsComplete,
            fetchNextChatInstruction,
            currentChatInstructionIndex,
            chatInstructions,
            handleSubmit,
            handleInputChange,
            chatInputError,
            userHasInteracted,
            isHoveringSend,
            setIsHoveringSend,
            placeholderText,
            inputRef,
            // Other states and functions to be exposed
        }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => {
    return useContext(ChatContext);
};
