import React, { useState, useEffect } from "react";
import styles from "./Dropdown.module.css";

const Dropdown = ({ 
    items, 
    label, 
    setSelectedItem, 
    placeholder = "-- Selecione um item --", 
    selectedItem = null, 
    minWidth,
    disabled = false 
}) => {    
    const [selectedItemId, setSelectedItemId] = useState("");

    // Set initial selected item based on the prop
    useEffect(() => {
        if (selectedItem) {
            setSelectedItemId(selectedItem.id.toString()); // Convert ID to string for consistency
        }
    }, [selectedItem]);

    const handleChange = (event) => {
        const itemId = event.target.value;
        setSelectedItemId(itemId);
        
        // Find the full item object by ID and pass it to the parent
        const selectedItem = items.find(item => item.id.toString() === itemId);
        setSelectedItem(selectedItem); 
    };

    return (
        <div className={styles.dropdownContainer}>
            <label htmlFor="dropdown">{label}</label>
            <select
                id="dropdown"
                value={selectedItemId}
                onChange={handleChange}
                style={{ minWidth: minWidth }}
                disabled={disabled}
            >
                <option value="" disabled>{placeholder}</option>
                {items.map((item) => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
