import { useState, useEffect } from 'react';
import { setupVSCode } from '../services/setupVsCodeService';
import { logEvent, logException, logTrace } from '../services/loggerFront';
import devEnvBoilerplates from '../dataInputs/devEnvBoilerplates.json';

export function useDevEnv(sections, testAttemptId, setTestStage, page, setBoilerplateCode) {
  const [devEnvNeeded, setDevEnvNeeded] = useState(false);
  const [stackItems, setStackItems] = useState([]);
  const [devEnvStack, setDevEnvStack] = useState(null);
  const [vsCodeIp, setVSCodeIp] = useState(null);
  const [showDevEnvPopup, setShowDevEnvPopup] = useState(false);

  const fileName = 'useDevEnv.js';

  useEffect(() => {

    // if it is not the interview, return
    if (!page || !page.includes('/entrevista')) {
      return;
    }

    if (!sections || sections.length === 0) {
      return;
    }

    if (!testAttemptId) {
      return
    }

    const devEnvSections = sections.filter(section => section.UI && section.UI.devEnv);
    if (devEnvSections.length === 0) {
      setDevEnvNeeded(false);
      return;
    }

    setDevEnvNeeded(true);
    const devEnvSection = devEnvSections[0];

    // Set stack items
    if (devEnvSection.coding && devEnvSection.coding.stacks) {
      const stackItems = devEnvSection.coding.stacks;
      setStackItems(stackItems);
    }

    // Determine if we need to show the dev env popup
    const needPopup = (stackItems.length > 1);
    setShowDevEnvPopup(needPopup);

  }, [sections, testAttemptId]);

  const handleDevEnvConfirm = async () => {
    if (!devEnvNeeded) {
      return;
    }

    getBoilerplateCode();

    try {
      const script = `setup-${devEnvStack.name.toLowerCase()}.sh`;
      setTestStage('introChat')
      const response = await setupVSCode(testAttemptId, script);
      if (response.message === 'Dev environment created successfully') {
        const ip = response.app_url;
        setVSCodeIp(ip);
      } else {
        logException("Unexpected response from setupVSCode:", response);
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      setTestStage('vsCodeLoadFail')
      logException("Error in setupVSCode:", {
        errorMessage: error.message,
        errorStack: error.stack,
        testAttemptId,
        fileName,
      });
      console.error("Error in setupVSCode:", error);
    }

  };

  const getBoilerplateCode = () => {
    // Set the boilerplate code based on the dev env
    const boilerplateKey = `${devEnvStack.name.toLowerCase()}`;
    const selectedBoilerplate = devEnvBoilerplates[boilerplateKey];
    if (selectedBoilerplate) {
      const parsedBoilerplate = parseBoilerplate(selectedBoilerplate);
      setBoilerplateCode(parsedBoilerplate);
    } else {
      logException("Boilerplate not found for:", {
        boilerplateKey,
        fileName,
      });
      console.error("Boilerplate not found for:", boilerplateKey);
    }
  }

  const parseBoilerplate = (boilerplate) => {
    return Object.entries(boilerplate)
      .map(([filename, content]) => `File: ${filename}\n\n${content}\n\n`)
      .join('---\n\n');
  };

  return {
    devEnvNeeded,
    stackItems,
    devEnvStack,
    setDevEnvStack,
    handleDevEnvConfirm,
    vsCodeIp,
    showDevEnvPopup,
    setShowDevEnvPopup
  };
}