// Interface with dynamic link to iframe for vscode instance
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import { useAppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import Button from '../common/Button';
import Tooltip from '../tooltip/Tooltip';
import { Tabs, Tab } from '../common/Tabs';
import FadeLoader from '../common/FadeLoader';
import { TooltipContext } from '../../contexts/TooltipContext';
import styles from './NewIde.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const NewIde = ({ tabsPresent = false }) => {
    // Global states
    const { theme, 
        showConfirmModal, 
        setShowConfirmModal, 
        handleConfirmIdeSubmission, 
        confirmSubmitCode, 
        showEmptyEditorAlert, 
        setShowEmptyEditorAlert 
    } = useCodeContext(); 
    const { vsCodeIp, isTestStarted } = useTestContext();
    const [vsCodeReady, setVsCodeReady] = useState(false);

    // UI interactions
    const { activeTooltips } = useContext(TooltipContext);
    const ideContainerRef = useRef(null);
    const { isInteractingWithIframe, setIsInteractingWithIframe, handleBlur } = useAppContext();
    const iframeRef = useRef(null);
    const [iframeKey, setIframeKey] = useState(0);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const fileName = 'NewIde';

    // Retry mechanism to make sure the iframe has loaded
    useEffect(() => {
        let retryTimeout;

        const checkIframeLoaded = () => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                setIframeLoaded(true);
            } else {
                // If not loaded, retry after a short delay
                retryTimeout = setTimeout(() => {
                    setIframeKey(prevKey => prevKey + 1);
                }, 2000);
            }
        };

        // Check iframe loaded status after a short delay
        const initialCheckTimeout = setTimeout(checkIframeLoaded, 1000);

        return () => {
            clearTimeout(initialCheckTimeout);
            clearTimeout(retryTimeout);
        };
    }, [iframeKey]);

    // reload iframe when isTestStarted turns true
    useEffect(() => {
        if (!isTestStarted) {
            setTimeout(() => {
                setIframeKey(prevKey => prevKey + 1);
            }, 2000);
        }
    }, [isTestStarted]);

    // FUNCTIONS FOR BUTTONS/DROPDOWNS //

    // Determine the theme class
    const themeClass = theme === 'dark' ? styles.dark : styles.light;

    // TOOLTIPS //

    const showVsCodeTooltip = activeTooltips['vsCode'];
    const showIdeSubmitTooltip = activeTooltips['ideSubmit'];

    const dynamicWidthStyle = {
        width: '100%'
    };

    // IFRAME //

    // Monitor interaction to prevent blur event from firing when interacting with the iframe
    const interactionIntervalRef = useRef(null);
  
    useEffect(() => {
        const iframeElement = iframeRef.current;
    
        // Check if the user has started interacting with the iframe
        const handleWindowBlur = () => {
            if (document.activeElement === iframeElement) {
                // User started interacting with the iframe
                setIsInteractingWithIframe(true);
                startInteractionInterval();
            } 
        };
  
        // Check if the document still has focus
        const startInteractionInterval = () => {
            // Clear any existing interval
            clearInterval(interactionIntervalRef.current);
    
            // Start a new interval
            interactionIntervalRef.current = setInterval(() => {

                // if the document no longer has focus, they have left the iframe and site
                if (!document.hasFocus()) {
                    setIsInteractingWithIframe(false);
                    clearInterval(interactionIntervalRef.current);
                    handleBlur();
                }
            }, 2000); // Check every Xms
        };
    
        window.addEventListener('blur', handleWindowBlur);
    
        // Cleanup event listeners and intervals on component unmount
        return () => {
                window.removeEventListener('blur', handleWindowBlur);
                clearInterval(interactionIntervalRef.current);
        };
    }, [isInteractingWithIframe]);

    return (
        <div className={`${styles.ide} ${themeClass}`} style={dynamicWidthStyle} ref={ideContainerRef}>

            <div className={styles.mainTab}>

                <div className={`${styles.ideTopRibbon} ${themeClass}`}>
                </div>

                <div className={styles.iframeContainer}>
                    {!isTestStarted && <div className={styles.ideCover}></div>}
                    {showVsCodeTooltip && <Tooltip variant="vsCode" />}
                    <iframe 
                        ref={iframeRef}
                        key={iframeKey}
                        src={vsCodeIp}
                        className={styles.codeEditorIframe}
                        title="Code Editor"
                        onLoad={() => setIframeLoaded(true)}
                    />
                </div>

                {showConfirmModal && (
                    <div className={`${styles.confirmSubmitModel} ${themeClass}`}>
                        <div className={styles.confirmSubmitModelTitle}> Aviso </div>
                        <p>Tem certeza de que deseja submeter seu código?</p>
                        <div className={styles.confirmSubmitModelButtons}>
                            <Button 
                                onClick={handleConfirmIdeSubmission} 
                                type="proceed" 
                            >
                                Confirmar
                            </Button>
                            <Button 
                                onClick={() => setShowConfirmModal(false)}
                                type="subtle" 
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                )}

                {showIdeSubmitTooltip && <Tooltip variant="ideSubmit" />}

                <div className={`${styles.ideMiddleRibbon} ${themeClass}`}>

                    <Button type="submitCode" onClick={confirmSubmitCode} />

                    {showEmptyEditorAlert && (
                        <div className={styles.emptyEditorAlert}>
                            <div className={styles.emptyEditorAlertTitle}> Aviso </div>
                            <p>O editor não pode estar vazio.</p>
                            <Button
                                onClick={() => setShowEmptyEditorAlert(false)}
                                type="tooltip"
                            >
                                Ok
                            </Button>
                        </div>
                    )}
                    
                </div>
            </div>
        </div>
    );
};

export default NewIde;
