import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import styles from './EmailContentContainer.module.css';

const EmailContentContainer = ({ initialEmailContent, isEditable = true, setFinalEmailContent }) => {
    const [isHoveringCopy, setIsHoveringCopy] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [editableContent, setEditableContent] = useState(initialEmailContent);

    useEffect(() => {
        setEditableContent(initialEmailContent);
        setFinalEmailContent(initialEmailContent);
    }, [initialEmailContent]);

    const copyToClipboard = () => {
        setIsHoveringCopy(false);
        const contentToCopy = isEditable ? editableContent : emailContent;
        navigator.clipboard.writeText(contentToCopy).then(() => {
            setErrorMessage('');
            setSuccessMessage('Email copiado');
            setTimeout(() => { setSuccessMessage(''); }, 3000);
        }).catch(() => {
            setErrorMessage('Erro ao copiar o email');
        });
    };

    const handleCopyMouseEnter = () => {
        setIsHoveringCopy(true);
    };

    const handleCopyMouseLeave = () => setIsHoveringCopy(false);

    const handleContentChange = (event) => {
        setEditableContent(event.target.value);
        setFinalEmailContent(event.target.value);
    };

    return (
        <div className={styles.emailContentContainer}>
            <div className={styles.emailAndButtonContainer}>
                {isEditable ? (
                    <textarea 
                        className={styles.emailContentEditable} 
                        value={editableContent} 
                        onChange={handleContentChange} 
                    />
                ) : (
                    <div className={styles.emailContent}>{initialEmailContent}</div>
                )}
                <div className={styles.copyButtonContainer}>
                    <button onClick={copyToClipboard} className={styles.copyButton} aria-label="Copiar texto">
                        <FontAwesomeIcon 
                            icon={faCopy} 
                            onMouseEnter={handleCopyMouseEnter}
                            onMouseLeave={handleCopyMouseLeave}
                        />
                    </button>
                    {isHoveringCopy && (
                        <div className={styles.copyHoverMessage}>
                            Copiar email
                        </div>
                    )}
                </div>
            </div>
            {errorMessage && (
                <div className={styles.errorMessage}>
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className={styles.successMessage}>
                    {successMessage}
                </div>
            )}
        </div>
    );
};

export default EmailContentContainer;
