import React, { useState, useRef, useEffect } from 'react';
import { detectSilence, detectClipping } from '../../utils/audioAnalysis';
import Button from '../common/Button';
import AudioVisualizer from './AudioVisualizer';
import { WavStreamPlayer } from '../../lib/wavtools/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faHeadphones } from '@fortawesome/free-solid-svg-icons';
import styles from './AudioCheck.module.css';

const AudioCheck = ({ onTestComplete }) => {
  const [isMicTesting, setIsMicTesting] = useState(false);
  const [isSpeakerTesting, setIsSpeakerTesting] = useState(false);
  const [startedMicTest, setStartedMicTest] = useState(false);
  const [startedSpeakerTest, setStartedSpeakerTest] = useState(false);
  const testToneIntervalRef = useRef(null);
  const [testsPassed, setTestsPassed] = useState({ mic: false, speaker: false });
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [visualizerData, setVisualizerData] = useState(null);

  const [audioQualityFeedback, setAudioQualityFeedback] = useState('');

  const wavPlayer = useRef(null);
  const audioStream = useRef(null);
  const audioContextRef = useRef(null);
  const sourceRef = useRef(null);
  const processorRef = useRef(null);

    useEffect(() => {
        async function initializeAudio() {
            try {
                // Initialize WavStreamPlayer with default settings
                wavPlayer.current = new WavStreamPlayer({ 
                    sampleRate: 24000
                });
                // Make sure to await the connect call
                await wavPlayer.current.connect();
                console.log('WavStreamPlayer initialized successfully');
            } catch (err) {
                console.error('Failed to initialize WavStreamPlayer:', err);
            }
        }

        initializeAudio();

        return () => {
            if (wavPlayer.current) {
                // wavPlayer.current.disconnect();
            }
        };
    }, []);

    const startMicTest = async () => {
        setStartedMicTest(true);
        stopSpeakerTest();
        try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
            audio: {
            sampleRate: 24000,
            channelCount: 1,
            echoCancellation: true,
            noiseSuppression: true,
            }
        });
        audioStream.current = stream;

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
            sampleRate: 24000,
        });
        sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
      
        // Create an AnalyserNode
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 1024; // this value is used to calculate the frequency bins
  
        // Connect the source to the analyser
        sourceRef.current.connect(analyserRef.current);
  
        // Start the animation loop
        animationFrameRef.current = requestAnimationFrame(updateVisualizerData);
  
        setIsMicTesting(true);
      } catch (error) {
        setMicFeedback('Erro ao acessar o microfone. Por favor, verifique suas configurações e tente novamente.');
      }
    };
  
    const updateVisualizerData = () => {
        if (!analyserRef.current || !audioContextRef.current) return;
    
        const sampleRate = audioContextRef.current.sampleRate;
        const frequencyData = new Uint8Array(analyserRef.current.frequencyBinCount);
        analyserRef.current.getByteFrequencyData(frequencyData);
    
        // Calculate the frequency range we want (80 Hz to 3000 Hz)
        const lowIndex = Math.floor(80 / (sampleRate / analyserRef.current.fftSize));
        const highIndex = Math.ceil(300 / (sampleRate / analyserRef.current.fftSize));
    
        const voiceFrequencies = frequencyData.slice(lowIndex, highIndex + 1);
    
        const frequencies = {
            values: Array.from(voiceFrequencies).map(value => value / 255), // Normalize to 0-1 range
            min: 80,
            max: 300,
        };

        // Perform additional audio quality checks
        const silenceDetected = detectSilence(frequencies.values);
        const clippingDetected = detectClipping(frequencies.values);

        updateAudioQualityFeedback(frequencies, silenceDetected, clippingDetected);

        setVisualizerData(frequencies);
        animationFrameRef.current = requestAnimationFrame(updateVisualizerData);
    };

    const updateAudioQualityFeedback = (frequencies, silenceDetected, clippingDetected) => {
        const volume = frequencies.values.reduce((sum, value) => sum + value, 0) / frequencies.values.length;
    
        let feedback = '';
    
        if (silenceDetected) {
            feedback = 'Não estamos detectando sua voz. Por favor, fale mais alto ou verifique se seu microfone está funcionando corretamente.';
        } else if (clippingDetected) {
            feedback = 'O volume está muito alto e pode estar causando distorção. Por favor, fale um pouco mais baixo ou afaste-se do microfone.';
        } else if (volume < 0.2) {
            feedback = 'O volume do seu microfone está muito baixo. Por favor, fale mais alto ou ajuste seu microfone.';
        } else if (volume > 0.8) {
            feedback = 'O volume do seu microfone está muito alto. Por favor, fale mais baixo ou afaste-se do microfone.';
        } else {
            feedback = 'A qualidade do áudio parece boa!';
        }
    
        setAudioQualityFeedback(feedback);
    };    

  const stopMicTest = () => {
    setIsMicTesting(false);
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current = null;
    }
    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close().then(() => {
        audioContextRef.current = null;
      });
    }
    if (audioStream.current) {
      audioStream.current.getTracks().forEach(track => track.stop());
      audioStream.current = null;
    }
    if (startedMicTest) {
        setTestsPassed(prev => ({ ...prev, mic: true }));
    }
  };

    // SPEAKER TEST //

    const startSpeakerTest = () => {
        setIsSpeakerTesting(true);
        setStartedSpeakerTest(true);
        stopMicTest();
      
        // Generate a short test tone (0.5 seconds)
        const testToneBuffer = generateTestTone(440, 1, 24000); // numbers are frequency, duration, sampleRate
      
        // Play the test tone repeatedly
        const playTestTone = () => {
            wavPlayer.current.add16BitPCM(testToneBuffer);
        };
      
        // Play immediately and then every 600ms
        playTestTone();
        testToneIntervalRef.current = setInterval(playTestTone, 2000);
    };
      
    const stopSpeakerTest = async () => {
        setIsSpeakerTesting(false);
        if (testToneIntervalRef.current) {
            clearInterval(testToneIntervalRef.current);
            testToneIntervalRef.current = null;
        }
        await wavPlayer.current.interrupt();
        
        // Reset the WavStreamPlayer
        wavPlayer.current = new WavStreamPlayer({ sampleRate: 24000 });
        await wavPlayer.current.connect().catch(error => {
            // do nothing for now
        });
        // update set test passed
        if (startedSpeakerTest) {
            setTestsPassed(prev => ({ ...prev, speaker: true }));
        }
    };

    // Clean up interval on component unmount
    useEffect(() => {
        return () => {
            if (testToneIntervalRef.current) {
                clearInterval(testToneIntervalRef.current);
            }
        };
    }, []);

    const generateTestTone = (frequency, duration, sampleRate) => {
        const samples = duration * sampleRate;
        const buffer = new Int16Array(samples);
        for (let i = 0; i < samples; i++) {
            buffer[i] = Math.sin(2 * Math.PI * frequency * i / sampleRate) * 32767;
        }
        return buffer;
    };

    // Handle output device change
    const handleOutputDeviceChange = async (e) => {
        const newDeviceId = e.target.value;
        setSelectedOutputDeviceId(newDeviceId);
        
        // Always stop the current test
        await stopSpeakerTest();
        
        // Create a new WavStreamPlayer with the new output device
        wavPlayer.current = new WavStreamPlayer({ 
            sampleRate: 24000, 
            outputDeviceId: newDeviceId 
        });
        await wavPlayer.current.connect().catch(error => {
            // do nothing for now
        });
        
        // If we were testing, restart the test
        if (isSpeakerTesting) {
            startSpeakerTest();
        }
    };

    const handleTestComplete = () => {
        if (testsPassed.mic && testsPassed.speaker) {
            onTestComplete();
        }
    };

    return (
        <div className={styles.audioCheck}>
            <div className={styles.audioCheckTitle}>Antes de começar, precisamos verificar seu microfone e alto-falante.</div>
            <div className={styles.testSection}>
                {!isMicTesting ? (
                    <div className={styles.testContainer}>
                        <div className={styles.containerTitle}>Teste de Microfone</div>
                        <FontAwesomeIcon icon={faMicrophone} size="5x" />
                        <Button onClick={startMicTest}>Iniciar Teste de Microfone</Button>
                    </div>
                ) : (
                    <div className={styles.testContainer}>
                        <AudioVisualizer 
                            isUserSpeaking={true} 
                            isAISpeaking={false} 
                            visualizerData={visualizerData} 
                        />
                        <div className={styles.micFeedback}>{audioQualityFeedback}</div>
                        <Button onClick={stopMicTest} type="red">Parar Teste de Microfone</Button>
                    </div>
                )}
            </div>
            <div className={styles.testSection}>
                {!isSpeakerTesting ? (
                    <div className={styles.testContainer}>
                        <div className={styles.containerTitle}>Teste de Alto-falante</div>
                        <FontAwesomeIcon icon={faHeadphones} size="5x" />
                        <Button onClick={startSpeakerTest}>Iniciar Teste de Alto-falante</Button>
                    </div>
                ) : (
                    <div className={styles.testContainer}>
                        <div className={styles.speakerTestText}>Você deve ouvir um tom de teste, se não ouvir, por favor, ajuste seu alto-falante.</div>
                        <Button onClick={stopSpeakerTest} type="red">Parar Teste de Alto-falante</Button>
                    </div>
                )}
            </div>
            {testsPassed.mic && testsPassed.speaker && (
                <Button onClick={handleTestComplete}>Continuar para o Roleplay</Button>
            )}
        </div>
    );
};

export default AudioCheck;