import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import Button from '../common/Button';
import AudioCheck from '../voice/AudioCheck';
import useRealtimeVoiceChat from '../../hooks/useRealtimeVoiceChat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash, faUser } from '@fortawesome/free-solid-svg-icons';
import { BounceLoader, BeatLoader, PulseLoader, DotLoader } from 'react-spinners';
import styles from './RolePlayInterface.module.css';

const RolePlayInterface = () => {
    const { webSocketUrl } = useAppContext();
    // Get the case instructions
    const { caseInstructions, currentSection } = useTestContext();
    const sectionName = currentSection.sectionName;
    const systemPrompt = caseInstructions[sectionName].systemPrompt;
    const candidateSummary = caseInstructions[sectionName].candidateSummary;
    const characterName = caseInstructions[sectionName].characterName;
    const characterImageName = caseInstructions[sectionName].image;
    let characterImage;
    try {
        characterImage = characterImageName ? require(`../../assets/images/${characterImageName}`) : null;
    } catch (error) {
        characterImage = null; // or a default image path
    }
    const { 
        backendWsReady, 
        aiWsReady,
        isStreaming, 
        userSpeaking, 
        aiSpeaking, 
        isDialing,
        visualizerData,
        transcript, 
        startStreaming, 
        stopStreaming 
    } = useRealtimeVoiceChat(webSocketUrl, systemPrompt);
    const [audioCheckComplete, setAudioCheckComplete] = useState(false);
    
    const handleAudioCheckComplete = () => {
        setAudioCheckComplete(true);
    };

    const handleToggleStreaming = async () => {
        if (isStreaming || isDialing) {
            console.log('Stopping streaming');
            await stopStreaming();
        } else {
            await startStreaming();
        }
    };

    const renderStatusIndicator = () => {
        const color = "#246e24";
        if (userSpeaking) {
            return (
                <div className={styles.statusIndicator}>
                    <BounceLoader color={color} size={60} />
                    <p>Você está falando...</p>
                </div>
            );
        } else if (aiSpeaking) {
            return (
                <div className={styles.statusIndicator}>
                    <BeatLoader color={color} size={60} />
                    <p>{characterName} está falando...</p>
                </div>
            );
        } else if (isStreaming) {
            return (
                <div className={styles.statusIndicator}>
                    <DotLoader color={color} size={60} />
                    <p>Ouvindo...</p>
                </div>
            );
        } else if (isDialing) {
            return (
                <div className={styles.statusIndicator}>
                    <PulseLoader color={color} size={40} />
                    <p>Conectando...</p>
                </div>
            );
        } else if (aiWsReady) {
            return (
                <div className={styles.statusIndicator}>
                    {characterName}
                </div>
            );
        } else {
            return (
                <div className={styles.statusIndicator}>
                    <p>Desconectado</p>
                </div>
            );
        }
    };

    const renderTranscript = () => {
        if (!transcript || transcript.length === 0) {
            return <div className={styles.emptyTranscript}>Não há mensagens ainda.</div>;
        }
        
        return transcript.map((entry, index) => (
            <div key={index} className={styles[entry.speaker.toLowerCase()]}>
                <strong>{entry.speaker}:</strong> {entry.message}
            </div>
        ));
    };

    if (!audioCheckComplete) {
        return <AudioCheck onTestComplete={handleAudioCheckComplete} />;
    }

    return (
        <div className={styles.rolePlayInterface}>
            <div className={styles.characterContainer}>
                <div className={styles.characterTitle}>Instruções</div>
                <div className={styles.characterScenario}>{candidateSummary}</div>
                <div className={styles.characterImage}>
                    <img 
                        src={characterImage} 
                        alt={characterName} 
                        className={styles.characterImage} 
                    />
                </div>
                {renderStatusIndicator()}
                <div className={styles.controls}>
                    <Button 
                        onClick={handleToggleStreaming} 
                        disabled={!aiWsReady}
                        type={isStreaming || isDialing ? 'red' : 'default'}
                    >
                        {isStreaming || isDialing ? (
                            <>
                                <FontAwesomeIcon icon={faPhoneSlash} />  Encerrar Chamada
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faPhone} />  Iniciar Chamada
                            </>
                        )}
                    </Button>
                </div>
            </div>
            <div className={styles.transcriptContainer}>
                <div className={styles.transcriptTitle}>Transcrição</div>
                <div className={styles.transcript}>
                    {renderTranscript()}
                </div>
            </div>
        </div>
    );
};

export default RolePlayInterface;