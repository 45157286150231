import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { TooltipProvider } from "../contexts/TooltipContext";
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import useCompanyTestData from "../hooks/useCompanyTestData";
import useEnsureUserData from '../hooks/useEnsureUserData';
import useFilterTestSummary from "../hooks/useFilterTestSummary";
import { useSortData } from "../hooks/useSortData";
import useAuth from "../hooks/useAuth";
import { hasPermission } from "../utils/hasPermission";
import { getPermissionLevel } from '../utils/getPermissionLevel';
import getRelativeTime from '../utils/getRelativeTime';
import { getCompanyTestsTableColumns } from '../dataInputs/companyTestsTableColumns'; // Functions to get columns for the user management tables
import { logException, logEvent, logTrace } from "../services/loggerFront";
import { addCompanyTest, removeCompanyTest } from "../services/companyTestService";
// Standard page components
import Header from "../components/common/Header";
import FreeTrialBanner from "../components/common/FreeTrialBanner";
import TopRibbon from "../components/common/TopRibbon";
import ReportIssue from "../components/common/ReportIssue";
import FadeLoader from '../components/common/FadeLoader';
import AccessDenied from '../components/common/AccessDenied';
import ActionButton from '../components/common/ActionButton';
import ConfirmModal from "../components/common/ConfirmModal";
import Button from "../components/common/Button";
import CompanyPageSidebar from '../components/common/CompanyPageSidebar';
import CompanyPageSidebarToggle from '../components/common/CompanyPageSidebarToggle';
import AdminModal from '../components/common/AdminModal';
import EditInterviewForm from '../components/inputs/EditInterviewForm';
import SortableTable from "../components/common/SortableTable";
import TestSummarySidebarFilters from "../components/report/TestSummarySidebarFilters";
import InviteCandidateModal from "../components/companyPortal/InviteCandidateModal";
import InterviewOverviewModal from "../components/companyPortal/InterviewOverviewModal";
import AddTestModal from "../components/companyPortal/AddTestModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faBars, faSync, faUserPlus, faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 
import tableStyles from "../sharedStyles/TableStyles.module.css";

const CompanyTestsPage = () => {
    const navigate = useNavigate(); // Function to navigate to a different page
    const { checkAndHandleTokenExpiration } = useAuth();
    const { userPermissions, isOrgAdmin, companyAccountType } = useAppContext();
    const { companyDetails } = useTestContext();

    // Permissions and user data
    const { fetchUserData } = useEnsureUserData();
    // on load fetch check user is logged and get user data
    useEffect(() => {
        checkAndHandleTokenExpiration();
        fetchUserData();
    }, []);
    const [canViewInterviews, setCanViewInterviews] = useState(false); // State to check if the user has the VIEW_USERS permission
    const [canEditInterviews, setCanEditInterviews] = useState(false); // State to check if the user has the EDIT_INTERVIEWS permission
    const [canAddInterviews, setCanAddInterviews] = useState(false); // State to check if the user has the ADD_INTERVIEWS permission
    const [canRemoveInterviews, setCanRemoveInterviews] = useState(false); // State to check if the user has the REMOVE_INTERVIEWS permission
    const [canInviteCandidates, setCanInviteCandidates] = useState(false); // State to check if the user has the INVITE_CANDIDATES permission
    const [viewPermissionLevel, setViewPermissionLevel] = useState(null); // State to check the permission level of the user

    // check permissions 
    const checkPermissions = () => {
        // if permissions are not set, return
        if (!userPermissions) return;
        // check if the user has the permission to view and edit interviews
        const canViewInterviews = hasPermission(userPermissions, 'VIEW_INTERVIEWS');
        const canEditInterviews = hasPermission(userPermissions, 'EDIT_INTERVIEWS');
        const canAddInterviews = hasPermission(userPermissions, 'ADD_INTERVIEWS');
        const canRemoveInterviews = hasPermission(userPermissions, 'REMOVE_INTERVIEWS');
        setCanViewInterviews(canViewInterviews);  
        setCanEditInterviews(canEditInterviews);
        setCanAddInterviews(canAddInterviews);
        setCanRemoveInterviews(canRemoveInterviews);
        // get the permission level of the user
        const viewPermissionLevel = getPermissionLevel(userPermissions, 'VIEW_INTERVIEWS');
        setViewPermissionLevel(viewPermissionLevel);
        // check if the user has the permission to invite candidates
        const canInviteCandidates = hasPermission(userPermissions, 'INVITE_CANDIDATES');
        setCanInviteCandidates(canInviteCandidates);
    };

    // call the checkPermissions function when userPermissions change
    useEffect(() => {
        checkPermissions();
    }, [userPermissions]);
    
    const [errorMessage, setErrorMessage] = useState(null); // Error message to display when there is an error
    const [noTestData, setNoTestData] = useState(false); // State to check if there is no test data
    const [modalLoading, setModalLoading] = useState(false); // State to store the loading state of the modal
    const { companyList,
        roleList,
        companyTestData, 
        testOverviews,
        totalCount,
        roleCounts,
        companyCounts,
        allTestNames,
        isLoadingInitialData, 
        refreshData,
        handleTestOverview,
        handleEditTest,
    } = useCompanyTestData(viewPermissionLevel, isOrgAdmin, companyDetails, setErrorMessage, setNoTestData, setModalLoading); // Hook to fetch the company data
    // Sorting hooks for users and permissions
    const { items: sortedCompanyTests, requestSort: requestCompanyTestsSort, sortConfig: companyTestsSortConfig } = useSortData(companyTestData, { key: 'latestTimestamp', direction: 'descending' });
    const { 
        filteredData, 
        roleFilters,
        companyFilters,
        selectAll, 
        testNameFilter, 
        handleRoleFilterChange,
        handleCompanyFilterChange,
        handleSelectAll,
        handleTestNameChange,
    } = useFilterTestSummary(sortedCompanyTests); // Hook to filter the data based on the selected roles
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Manage the visibility of the sidebar with filters

    // States for modals
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false); // State to show the invite candidate modal
    const [showInterviewOverview, setShowInterviewOverview] = useState(false); // State to show the interview overview modal
    const [showAddTestModal, setShowAddTestModal] = useState(false); // State to show the add test modal
    const [showAdminModal, setShowAdminModal] = useState(false); // State to show the admin modal
    const [modalType, setModalType] = useState(''); // State to store the type of modal to show
    const [editedTestName, setEditedTestName] = useState(''); // State to store the edited test name
    const [editedRoleName, setEditedRoleName] = useState(''); // State to store the edited role name
    const [selectedTest, setSelectedTest] = useState(null); // State to store the selected test id for the interview overview
    const testTableColumns = getCompanyTestsTableColumns(viewPermissionLevel, companyList);

    const fileName = "CompanyTestsPage.js"; 

    // Toggle the visibility of the sidebar
    const handleSidebarToggle = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    // Use effect to automatically hide the sidebar on small screens
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsSidebarVisible(false); // Hide sidebar when screen is small
            }
        };

        // Call handleResize on initial load
        handleResize();

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (companyTestData) {
            if (companyTestData.length < 2) {
                setIsSidebarVisible(false);
            }
        }
    }, [companyTestData]);

    const handleCloseInviteModal = () => {
        setShowInviteModal(false);
        setSelectedTest(null);
    };

    const handleCloseInterviewOverviewModal = () => {
        setShowInterviewOverview(false);
        setSelectedTest(null);
    };

    const handleCloseAddTestwModal = () => {
        setShowAddTestModal(false);
        setSelectedTest(null);
    };

    // Set the test and show the invite modal
    const handleInviteCandidate = (row) => {
        // check if there is a row, if not continue with the selected test
        if (row) {
            setSelectedTest(row);
        }
        setShowAddTestModal(false);
        setShowInterviewOverview(false);
        setShowInviteModal(true);
    };

    const handleAddTest = async () => {
        const testId = selectedTest.id;
        const companyId = companyDetails.companyID;
        
        try {
          const result = await addCompanyTest(testId, companyId);
          // Optionally, you can refresh the data or update the state here
          refreshData();
          setShowAddTestModal(false);
        } catch (error) {
          // Handle the error (e.g., show an error message to the user)
        }
    };

    const handleInterviewOveriewClick = (row) => {
        const testId = row.testId;
        handleTestOverview(testId);
        setSelectedTest(row);
        setShowInterviewOverview(true);
    }

    const handleEditInterview = (row) => {
        setSelectedTest(row);
        setEditedTestName(row.testName);
        setEditedRoleName(row.roleName);
        setShowAdminModal(true);
        setModalType('editTest');
    }

    // Add interview
    const handleAddInterviewClick = () => {
        setShowAddTestModal(true);
    }

    // Remove interview
    const handleRemoveClick = (row) => {
        setSelectedTest(row); // Store the selected test
        setShowConfirmModal(true); // Show the confirm modal for the user to confirm
    };

    // Confirm and remove the user
    const handleConfirmRemove = () => {
        if (selectedTest) {
            handleRemoveInterview(selectedTest);
        }
        setShowConfirmModal(false);
        setSelectedTest(null);
    };

    // Cancel the remove action
    const handleCancelRemove = () => {
        setShowConfirmModal(false);
        setSelectedTest(null);
    };

    const handleRemoveInterview = async (row) => {
        const companyTestId = row.companyTestId;
        await removeCompanyTest(companyTestId);
        refreshData();
    }

    // Close AdminModal
    const handleCloseAdminModal = () => {
        setShowAdminModal(false);
        setSelectedTest(null);
    };

    // Handle Confirm action for AdminModal
    const handleConfirmAdminModal = async () => {
        try {
            if (modalType === 'editTest') {
                // Gather the updated data for the selected user
                const testData = {
                    custom_name: editedTestName,   // Gather this from your EditUserForm state
                    custom_role: editedRoleName, // Gather this from your EditUserForm state
                };
                const companyTestId = selectedTest.companyTestId;
                // Call the editUser function with the selected user ID and the gathered data
                await handleEditTest(companyTestId, testData);
                refreshData();
            }
    
            // If successful, close the modal
            setShowAdminModal(false);
            setSelectedTest(null);
        } catch (error) {
            // If an error occurs, keep the modal open and display the error
            logException('Error editing test:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName: fileName,
            });
        }
    };

    // TABLE //

    // Actions for main table
    const renderActions = (row) => {
    
        return (
            <>
                <ActionButton
                    icon={faBars}
                    label="Ver Detalhes"
                    onClick={() => handleInterviewOveriewClick(row)}
                    showHoverPopout={true}
                    popoutText="Ver detalhes"
                    popoutPosition="left"
                />
                {canEditInterviews && (
                    <ActionButton
                        icon={faEdit}
                        label="Editar Entrevista"
                        onClick={() => handleEditInterview(row)}
                        showHoverPopout={true}
                        popoutText="Editar entrevista"
                        popoutPosition="left"
                    />
                )}
                {canInviteCandidates && (
                    <ActionButton
                        icon={faUserPlus}
                        label="Convidar Candidatos"
                        onClick={() => handleInviteCandidate(row)}
                        showHoverPopout={true}
                        popoutText="Convidar candidatos"
                        popoutPosition="left"
                    />
                )}
                {canRemoveInterviews && (
                    <ActionButton
                        icon={faTrash}
                        label="Remover Entrevista"
                        onClick={() => handleRemoveClick(row)}
                        showHoverPopout={true}
                        popoutText="Excluir entrevista"
                        popoutPosition="left"
                    />
                )}
            </>
        );
    };

    // Rows for main table
    const renderRow = (row) => [
        <td key="testName" className={tableStyles.boldColumn} style={{ minWidth: '250px' }}>
            <ActionButton 
                text={row.testName}
                label={`Ver detalhes para ${row.testName}`}
                onClick={() => handleInterviewOveriewClick(row)}
                showHoverPopout={true}
                popoutText={`Ver detalhes para ${row.testName}`}
                popoutPosition="right"
            />
        </td>,
        viewPermissionLevel === 'GLOBAL' && <td key="companyName">{row.companyName}</td>,
        <td key="roleName">{row.roleName}</td>,
        <td key="resultCount" className={tableStyles.centeredColumn}>
            <ActionButton 
                text={row.resultCount}
                label={`Ver resultados`}
                onClick={() => navigate('/resultados')}
                showHoverPopout={true}
                popoutText={`Ver resultados`}
                popoutPosition="right"
            />
        </td>,
        <td key="timestamp">{row.latestTimestamp ? getRelativeTime(row.latestTimestamp) : null}</td>,
        <td key="actions" className={tableStyles.centered65Column}>
            {renderActions(row)}
        </td>
    ].filter(Boolean);

    // Show access denied message if the user doesn't have permission
    if (!canViewInterviews) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Painel de entrevistas" />
                    </div>
                    <div className={styles.contentContainer}>
                        <AccessDenied />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    // Show loading spinner while fetching data
    if (isLoadingInitialData) {
        return (
            <TooltipProvider>
                <ReportIssue/>
                <div className={`${styles.outerContainer} ${styles.noSidebar}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    <div className={styles.ribbon}>
                        <TopRibbon title="Painel de entrevistas" />
                    </div>
                    <div className={styles.contentContainer}>
                        <FadeLoader text="Carregando dados..." />
                    </div>
                </div>
            </TooltipProvider>
        );
    }

    return (
        <>
            <TooltipProvider>
            <ReportIssue/>
                {showInviteModal && (
                    <InviteCandidateModal
                        onClose={handleCloseInviteModal}
                        selectedTest={selectedTest}
                        companyDetails={companyDetails}
                        >
                    </InviteCandidateModal>
                )}
                {showInterviewOverview && (
                    <InterviewOverviewModal
                        testOverviews={testOverviews}
                        onClose={handleCloseInterviewOverviewModal}
                        selectedTest={selectedTest}
                        handleInviteCandidate={handleInviteCandidate}
                        canInviteCandidates={canInviteCandidates}
                    >
                    </InterviewOverviewModal>
                )}
                {showAddTestModal && (
                    <AddTestModal
                        testNames={allTestNames}
                        currentTests={companyTestData}
                        testOverviews={testOverviews}
                        onClose={handleCloseAddTestwModal}
                        selectedTest={selectedTest}
                        setSelectedTest={setSelectedTest}
                        handleTestOverview={handleTestOverview}
                        handleAddTest={handleAddTest}
                    >
                    </AddTestModal>
                )}
                {showAdminModal && (
                    <AdminModal
                        title={modalType === 'addUser' ? 'Adicionar conta' : 'Editar conta'}
                        onClose={handleCloseAdminModal}
                        onConfirm={handleConfirmAdminModal}
                        errorMessage={errorMessage}
                        isLoading={modalLoading}
                    >
                        {modalType === 'editTest' && 
                            <EditInterviewForm 
                                editedTestName={editedTestName}
                                editedRoleName={editedRoleName}
                                setTestName={setEditedTestName} 
                                setRoleName={setEditedRoleName}
                            />
                        }
                    </AdminModal>
                )}
                {/* Confirm Modal for removing tests */}
                <ConfirmModal
                    show={showConfirmModal}
                    title="Excluir entrevista"
                    message="Tem certeza de que deseja excluir esta entrevista?"
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancelRemove}
                    confirmLabel="Confirmar"
                    cancelLabel="Cancelar"
                />
                <div className={`${styles.outerContainer} ${!isSidebarVisible ? styles.sidebarHidden : ''}`}>
                    <div className={styles.header}>
                        <Header />
                    </div>
                    {companyAccountType === 'free' && (
                        <div className={styles.banner}>
                            <FreeTrialBanner />
                        </div>
                    )}
                    <div className={styles.ribbon}>
                        <TopRibbon title="Painel de entrevistas">
                            {/*
                            <Button
                                label="Adicionar entrevista"
                                type="proceed"
                                onClick={handleAddInterviewClick}
                            >
                                <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} /> 
                                Adicionar entrevista
                            </Button>
                            */}
                        </TopRibbon>
                    </div>
                    {noTestData ? (
                        <div className={styles.contentContainer}>
                            <div className={styles.noTestDataContainer}>
                                <div className={styles.noTestDataHeader}>
                                    Vamos adicionar sua primeira entrevista!
                                </div>
                                <div className={styles.noTestDataText}>
                                    Clique no botão 'Adicionar entrevista' acima para começar.
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isSidebarVisible && (
                                <div className={styles.sidebar}>
                                    <CompanyPageSidebar
                                        header={
                                            <>
                                                <span>Filtros</span>
                                                <ActionButton 
                                                    icon={faSync} 
                                                    label="Atualizar Dados" 
                                                    onClick={refreshData}
                                                    showHoverPopout={true}
                                                    popoutText="Atualizar dados"
                                                    popoutPosition="left"
                                                />
                                            </>
                                        }
                                        isVisible={isSidebarVisible}
                                    >
                                        <TestSummarySidebarFilters
                                            roleList={roleList}
                                            roleFilters={roleFilters}
                                            handleRoleFilterChange={handleRoleFilterChange}
                                            totalCount={totalCount}
                                            roleCounts={roleCounts}
                                            handleSelectAll={handleSelectAll}
                                            selectAll={selectAll}
                                            testNameFilter={testNameFilter}
                                            handleTestNameChange={handleTestNameChange}
                                            companies={companyList}
                                            companyFilters={companyFilters}
                                            handleCompanyFilterChange={handleCompanyFilterChange}
                                            companyCounts={companyCounts}
                                            isGlobalAccess={viewPermissionLevel === 'GLOBAL'}
                                        />
                                    </CompanyPageSidebar>
                                </div>
                            )}
                            {companyTestData.length > 0 && (
                                <div className={styles.sidebarButton}>
                                    <CompanyPageSidebarToggle
                                        onToggle={handleSidebarToggle}
                                        toggleIcon={isSidebarVisible ? faChevronLeft : faChevronRight}
                                        toggleLabel={isSidebarVisible ? "Ocultar Barra Lateral" : "Mostrar Barra Lateral"}
                                    />
                                </div>
                            )}
                            <div className={styles.contentContainer} aria-label="Tabela resumo dos resultados dos candidatos" tabIndex="0">
                                <SortableTable
                                    columns={testTableColumns}
                                    data={filteredData}
                                    onSort={requestCompanyTestsSort}
                                    sortConfig={companyTestsSortConfig}
                                    renderRow={renderRow} 
                                    styles={tableStyles} 
                                />
                                {errorMessage && <p className={styles.tableErrorMessage}>{errorMessage}</p>}
                            </div>
                        </>
                    )}
                </div>
            </TooltipProvider>
        </>
    );
};

export default CompanyTestsPage;
