import React, { useRef, useEffect } from 'react';
import { useTestContext } from '../../contexts/TestContext';
import FadeLoader from '../common/FadeLoader';
import styles from './IdePopout.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const IdePopout = ({ onClose }) => {
    const { vsCodeIp } = useTestContext();
    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    if (!vsCodeIp) {
        return <FadeLoader text="Carregando VS Code..." height='100%'/>
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.container} ref={containerRef}>
                <div className={styles.topMessage}>
                    Seu código já foi submetido, quaisquer alterações não serão salvas.
                </div>
                <button className={styles.closeButton} onClick={onClose}>
                    <i className="fas fa-times"></i>
                </button>
                <div className={styles.iframeContainer}>
                    <iframe 
                        src={vsCodeIp}
                        className={styles.codeEditorIframe}
                        title="Code Editor"
                    />
                </div>
            </div>
        </div>
    );
};

export default IdePopout;