export const suggestedEmailTemplate = `Olá, tudo bem?\n
É com prazer que te convidamos para o desafio técnico da vaga {selectedTest.role}.\n
Trata-se de um desafio de programação através da plataforma Degrau, com duração estimada de {selectedTest.time} minutos.\n
Ao clicar no link você receberá todas as instruções para resolução da situação problema, será apresentado um enunciado com requisitos para um desafio que reflete o dia a dia da vaga; esperamos que você implemente um código que atenda aos requisitos.\n
{generatedLink}\n
Dicas sobre o processo:\n
- Durante o exercício você contará com a interação com a IA para tirar dúvidas de programação e sobre o desafio;\n
- Além da funcionalidade do código, avaliamos se a solução apresenta características de código limpo, boas práticas;\n
- Não se preocupe se ficar sem tempo para terminar o código, pois você terá a chance de explicar o que faria se tivesse mais tempo.\n
- Após submeter o código, você responderá algumas perguntas sobre arquitetura de software.\n
Boa sorte!`;
