// Control what users can copy and paste on the site and what they can bring out
import React, { createContext, useContext, useState } from 'react';
import { useAppContext } from './AppContext';
import { logEvent, logException, logTrace } from '../services/loggerFront';

export const CopyPasteContext = createContext();

export const CopyPasteProvider = ({ children, location }) => {
  const [copyStatus, setCopyStatus] = useState('not ok'); // 'ok' or 'not ok'
  const [canPaste, setCanPaste] = useState('');
  const [limitPaste, setLimitPaste] = useState('Não pode copiar e colar este conteúdo.');

  // If copied from instructions, replace the clipboard with the anti-fraud message, if from elsewhere you can allow it but store as canPaste to run check later
  const handleCopy = (text, source) => {
    if (location.pathname !== "/entrevista" && !location.pathname.includes('/entrevista/')) {
      return; // Dont block if not on test site
    }

    if (source === 'other') {
        setCopyStatus('not ok');
        // Change clipboard content to 'limitPaste' message
        navigator.clipboard.writeText('Não pode copiar e colar este conteúdo.').catch(err => logException('Failed to write to clipboard', {errorMessage: err.toString()} ));
        setLimitPaste(text);
    } else {
        setCopyStatus('ok');
        setCanPaste(text);
        // Do not modify the clipboard content; let the default action proceed
    }
  };

  // Capture what was being pasted in the not ok scenario. ok scenario check the paste content = the copied content before allowing, this is not is use as all copies onsite we replace with 'nao pode'
  const handlePaste = (e) => {
    if (location.pathname !== "/entrevista" && !location.pathname.includes('/entrevista/')) {
      return; // Dont block if not on test site
    }

    if (copyStatus === 'not ok') {
      navigator.clipboard.readText().then(clipText => {
        // Trim both strings and compare
        const trimmedClipText = clipText.trim();
        if (trimmedClipText !== 'Não pode copiar e colar este conteúdo.') {
            // in this case we can save the pasted content and increment a paste count
        }
    })
    } else if (copyStatus === 'ok') {
        // Check if the pasted content matches canPaste; if not, prevent the action or alert the user
        navigator.clipboard.readText().then(clipText => {
            // Trim both strings and compare
            const trimmedClipText = clipText.trim();
            const trimmedCanPaste = canPaste.trim();
            if (trimmedClipText !== trimmedCanPaste) {
                e.preventDefault(); // Prevent the paste action
                alert('Conteúdo copiado da fora detectado.');
            }
        }).catch(err => logException('Failed to read from clipboard', { errorMessage: err.toString()} ));
    }
};

  return (
    <CopyPasteContext.Provider value={{ handleCopy, handlePaste, copyStatus, canPaste, limitPaste }}>
      {children}
    </CopyPasteContext.Provider>
  );
};

export const useCopyPaste = () => useContext(CopyPasteContext);
