import { useState, useEffect, useCallback, useRef } from 'react';
import useConversationRecorder from './useConversationRecorder';
import { logEvent, logException } from '../services/loggerFront';
import { WavStreamPlayer } from '../lib/wavtools/index.js';

const useRealtimeVoiceChat = (webSocketUrl, systemPrompt) => {
    // WS
    const [ws, setWs] = useState(null);
    const [backendWsReady, setBackendWsReady] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const isStreamingRef = useRef(false);
    const [aiWsReady, setAiWsReady] = useState(false);
    // UI management
    const [userSpeaking, setUserSpeaking] = useState(false);
    const [aiSpeaking, setAiSpeaking] = useState(false);
    const lastPlaybackOffsetRef = useRef(null);
    const checkIntervalRef = useRef(null);
    const aiSpeakingTimeoutRef = useRef(null);
    const [isDialing, setIsDialing] = useState(false);
    const [visualizerData, setVisualizerData] = useState(null);
    // Transcript
    const [transcript, setTranscript] = useState([]);
    const userPlaceholderRef = useRef(null); // placeholder for the user's message to keep the space until input_transcript is received
    const currentAIMessageRef = useRef({ itemId: null, message: '' }); // So we can add the chunks to the correct message
    const textChunkQueue = useRef([]);
    const isAddingText = useRef(false);
    // Audio
    const audioStream = useRef(null);
    const audioContextRef = useRef(null);
    const sourceRef = useRef(null);
    const processorRef = useRef(null);
    const currentPlayingEvent = useRef(null);
    const dialingAudioRef = useRef(null);
    const [userAudioReady, setUserAudioReady] = useState(false);
    // Reconnect attempts
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const maxReconnectAttempts = 5;
    const reconnectTimeoutRef = useRef(null);

    const wavPlayer = useRef(null);
    const userWavPlayer = useRef(null);

    // log changes to isStreaming
    useEffect(() => {
        isStreamingRef.current = isStreaming;
    }, [isStreaming]);

    // Recording
    const {
        isRecording,
        startRecording,
        stopRecording,
        error: recordingError
    } = useConversationRecorder({
        wavPlayer: wavPlayer.current,
        onError: (error) => {
            console.error('Recording error:', error);
            logException('Conversation recording error', { error });
        }
    });

    useEffect(() => {
        // Initialize WavStreamPlayer for AI audio
        wavPlayer.current = new WavStreamPlayer({ sampleRate: 24000 });
        wavPlayer.current.connect().catch(error => {
            console.error('Failed to connect AI WavStreamPlayer:', error);
            logException('Failed to connect AI WavStreamPlayer', { error });
        });

        // Initialize WavStreamPlayer for user audio
        userWavPlayer.current = new WavStreamPlayer({ sampleRate: 24000 });
        userWavPlayer.current.connect().catch(error => {
            console.error('Failed to connect user WavStreamPlayer:', error);
            logException('Failed to connect user WavStreamPlayer', { error });
        });

        return () => {
            if (checkIntervalRef.current) {
                clearInterval(checkIntervalRef.current);
            }
            if (wavPlayer.current) {
                // wavPlayer.current.disconnect();
            }
            if (userWavPlayer.current) {
                // userWavPlayer.current.disconnect();
            }
        };
    }, []);

    // WEBSOCKET //

    const connectWebSocket = useCallback(() => {
        // if no websocket url or systemPrompt, return
        if (!webSocketUrl || !systemPrompt) return;

        const socket = new WebSocket(`${webSocketUrl}/realtime-voice-chat?systemPrompt=${systemPrompt}`);

        socket.onopen = () => {
            setBackendWsReady(true);
            setReconnectAttempts(0);
            logEvent('Realtime voice chat WebSocket connected');
        };

        socket.onmessage = handleWebSocketMessage;

        socket.onerror = (error) => {
            console.error('socket.onerror', error);
            logException('Realtime voice chat WebSocket error', { error });
        };

        socket.onclose = (event) => {
            setBackendWsReady(false);
            setIsStreaming(false);
            logEvent('Realtime voice chat WebSocket closed', { code: event.code, reason: event.reason });
            
            // Check if the closure was intentional
            if (event.code !== 1000) {
                handleReconnect();
            } else {
                // do nothing for now
            }
        };

        setWs(socket);
    }, [webSocketUrl, systemPrompt]);

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [connectWebSocket]);

    const handleReconnect = useCallback(() => {
        if (reconnectAttempts < maxReconnectAttempts) {
            console.log(`Attempting to reconnect (${reconnectAttempts + 1}/${maxReconnectAttempts})`);
            setReconnectAttempts(prev => prev + 1);
            
            const timeout = Math.pow(2, reconnectAttempts) * 1000;
            reconnectTimeoutRef.current = setTimeout(() => {
                connectWebSocket();
            }, timeout);
        } else {
            console.error('Max reconnection attempts reached');
            logException('Failed to reconnect to WebSocket after maximum attempts');
        }
    }, [reconnectAttempts, connectWebSocket]);

    // WEBSOCKET MESSAGES //

    const handleWebSocketMessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.type) {
            case 'audio':
                handleAudioData(data); // send each chunk
                break;
            case 'audio_transcript':
                addToTextChunkQueue(data.itemId, data.transcript);
                break;
            case 'input_transcript':
                updateUserTranscript(data.transcript);
                break;
            case 'alert':
                handleAlert(data.alert);
                break;
            case 'response_complete':
                setAiSpeaking(false);
                break;
            case 'eval':
                // console.log('received eval', data.eval[0].text);
                break;
            case 'error':
                console.log('realtime error', data.error);
                break;
            default:
                console.log('Unhandled message type:', data.type);
        }
    };

    const handleAlert = (alert) => {
        switch (alert) {
            case 'user_speech_detected':
                setUserSpeaking(true);
                addUserPlaceholder();
                stopAudioPlayback();
                stopTextAddition();
                break;
            case 'user_speech_completed':
                setUserSpeaking(false);
                break;
            case 'ai_response_detected':
                if (isStreamingRef.current) {
                    setAiSpeaking(true);
                }
                break;
            case 'azure_openai_connected':
                setAiWsReady(true);
                break;
            case 'azure_openai_closed':
                setAiWsReady(false);
                break;
            case 'ai_response_completed':
                break;
            // case 'ai_transcript_completed':
            //     console.log('ai_transcript_completed');
            //     currentAIMessage.current = ''; // Reset for the next AI message
            //     break;
            default:
                console.log('Unhandled alert:', alert);
        }
    };

    // STREAMING //

    const startStreaming = async () => {
        setTranscript([]);
        playDialingSound();
        setIsDialing(true);

        try {
            // Send start_streaming message to server to ensure the ws to Azure OpenAI is open
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ type: 'start_streaming' }));
            }

            // Wait for X seconds before opening the audio stream and stopping the dialing sound
            await new Promise(resolve => setTimeout(resolve, 5000));

            const stream = await navigator.mediaDevices.getUserMedia({ 
                audio: {
                    sampleRate: 24000,
                    channelCount: 1,
                    echoCancellation: true,
                    noiseSuppression: true,
                }
            });
            audioStream.current = stream;
            setUserAudioReady(true); 

            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
                sampleRate: 24000,
            });
            sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
            processorRef.current = audioContextRef.current.createScriptProcessor(2048, 1, 1);
    
            // Create a gain node and set its gain to 0 (silent)
            const gainNode = audioContextRef.current.createGain();
            gainNode.gain.setValueAtTime(0, audioContextRef.current.currentTime);
    
            // Connect the source to the processor, the processor to the gain node, and the gain node to the destination
            sourceRef.current.connect(processorRef.current);
            processorRef.current.connect(gainNode);
            gainNode.connect(audioContextRef.current.destination);
    
            processorRef.current.onaudioprocess = (e) => {
                const inputData = e.inputBuffer.getChannelData(0);
                const audioData = convertFloat32ToInt16(inputData);
                const base64Audio = btoa(String.fromCharCode.apply(null, new Uint8Array(audioData.buffer)));
                sendAudioChunk(base64Audio);
            };

            // Start recording only after stream is ready
            if (wavPlayer.current) {
                await startRecording(stream);
            }

            // Send start_conversation message to server to have the AI start the conversation
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ type: 'start_conversation' }));
            }
            
            // Stop dialing sound
            setIsDialing(false);
            stopDialingSound();

            setIsStreaming(true);
        } catch (error) {
            console.error('Error starting streaming:', error);
            logException('Error starting streaming', { error });
            setIsDialing(false);
            setIsStreaming(false);
            setUserAudioReady(false);
            stopDialingSound();
        }
    };

    const playDialingSound = () => {
        if (!dialingAudioRef.current) {
            dialingAudioRef.current = new Audio('/sounds/phone_dial_1.wav');
            dialingAudioRef.current.loop = true;
        }
        dialingAudioRef.current.play().catch(error => {
            console.error('Error playing dialing sound:', error);
            logException('Error playing dialing sound', { error });
        });
    };

    const stopDialingSound = () => {
        if (dialingAudioRef.current) {
            dialingAudioRef.current.pause();
            dialingAudioRef.current.currentTime = 0;
        }
    };

    const convertFloat32ToInt16 = (float32Array) => {
        const int16Array = new Int16Array(float32Array.length);
        for (let i = 0; i < float32Array.length; i++) {
            const s = Math.max(-1, Math.min(1, float32Array[i]));
            int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return int16Array;
    };
    
    const stopStreaming = useCallback(async () => {
        
        try {
            setIsStreaming(false);
            setIsDialing(false);
            setUserSpeaking(false);
            setAiSpeaking(false);
    
            // Stop recording first
            if (isRecording) {
                await stopRecording();
            } else {
                // do nothing
            }
            
            // Clean up audio processor
            if (processorRef.current) {
                processorRef.current.disconnect();
                processorRef.current.onaudioprocess = null;
                processorRef.current = null;
            }
    
            // Clean up source
            if (sourceRef.current) {
                sourceRef.current.disconnect();
                sourceRef.current = null;
            }
    
            // Clean up audio context
            if (audioContextRef.current) {
                await audioContextRef.current.close();
                audioContextRef.current = null;
            }
    
            // Clean up stream
            if (audioStream.current) {
                audioStream.current.getTracks().forEach(track => track.stop());
                audioStream.current = null;
            }
            
            // Send stop message to websocket
            if (ws && backendWsReady) {
                console.log('useRealtimeVoiceChat: Sending stop_streaming message');
                ws.send(JSON.stringify({ type: 'stop_streaming' }));
            }
        } catch (error) {
            console.error('useRealtimeVoiceChat: Error stopping streaming:', error);
            throw error; // Re-throw to be caught by the interface
        }
    }, [isRecording, stopRecording]);

    const sendAudioChunk = (audioData) => {
        if (ws && backendWsReady) {
            ws.send(JSON.stringify({ 
                type: 'input_audio_buffer.append', 
                audio: audioData 
            }));
        
            // Use the audio data for visualization only
            // const audioBuffer = base64ToInt16Array(audioData);
            // const frequencies = analyzeAudioFrequencies(audioBuffer);
            // setVisualizerData(frequencies);
        } else {
            console.error('WebSocket not connected or not streaming. Audio chunk not sent.');
        }
    };

    // TRANSCRIPT //

    // TRANSCRIPT - USER //

    const addUserPlaceholder = () => {
        const placeholderId = Date.now(); // Use timestamp as a unique ID
        setTranscript(prev => [...prev, { id: placeholderId, speaker: 'Você', message: '...' }]);
        userPlaceholderRef.current = placeholderId;
    };

    const updateUserTranscript = (userTranscript) => {
        setTranscript(prev => prev.map(item => 
            item.id === userPlaceholderRef.current
                ? { ...item, message: userTranscript }
                : item
        ));
        userPlaceholderRef.current = null;
    };

    // AI //

    const addToTextChunkQueue = (itemId, text) => {
        textChunkQueue.current.push({ itemId, text });
        if (!isAddingText.current) {
            processNextTextChunk();
        }
    };

    const processNextTextChunk = useCallback(() => {
        if (textChunkQueue.current.length === 0) {
            isAddingText.current = false;
            return;
        }

        isAddingText.current = true;
        const { itemId, text } = textChunkQueue.current.shift();

        updateAITranscript(itemId, text);

        // Simulate speech pace (adjust the delay as needed)
        setTimeout(() => {
            processNextTextChunk();
        }, text.length * 70); 
    }, []);

    const updateAITranscript = useCallback((itemId, newTranscript) => {
        setTranscript(prev => {
            let updatedTranscript = [...prev];
            const existingMessageIndex = updatedTranscript.findIndex(
                message => message.speaker === 'Claudia' && message.itemId === itemId
            );
    
            if (existingMessageIndex !== -1) {
                // Update existing message
                updatedTranscript[existingMessageIndex] = {
                    ...updatedTranscript[existingMessageIndex],
                    message: updatedTranscript[existingMessageIndex].message + newTranscript
                };
            } else {
                // Add new message
                updatedTranscript.push({
                    speaker: 'Claudia',
                    itemId: itemId,
                    message: newTranscript
                });
            }
    
            currentAIMessageRef.current = { itemId, message: updatedTranscript[updatedTranscript.length - 1].message };
            return updatedTranscript;
        });
    }, []);

    const stopTextAddition = useCallback(() => {
        isAddingText.current = false;
        textChunkQueue.current = [];
    }, []);

    // PLAY AUDIO //

    const startCheckingAudioPlayback = useCallback(() => {
        if (checkIntervalRef.current) {
            clearInterval(checkIntervalRef.current);
        }
        checkIntervalRef.current = setInterval(checkAudioPlayback, 100);
    }, []);

    const checkAudioPlayback = async () => {
        if (!wavPlayer.current) return;

        try {
            const currentOffset = await wavPlayer.current.getTrackSampleOffset();
            if (currentOffset && currentOffset.offset !== undefined) {
                if (lastPlaybackOffsetRef.current === null || currentOffset.offset > lastPlaybackOffsetRef.current) {
                    // Audio is playing
                    setAiSpeaking(true);
                    lastPlaybackOffsetRef.current = currentOffset.offset;
                } else if (currentOffset.offset === lastPlaybackOffsetRef.current) {
                    // Audio has stopped playing
                    setAiSpeaking(false);
                    clearInterval(checkIntervalRef.current);
                    lastPlaybackOffsetRef.current = null;
                }
            } else {
                // No audio playing
                setAiSpeaking(false);
                clearInterval(checkIntervalRef.current);
                lastPlaybackOffsetRef.current = null;
            }
        } catch (error) {
            console.error('Error checking audio playback:', error);
            logException('Error checking audio playback', { error });
        }
    };

    const handleAudioData = (data) => {
        if (!isStreamingRef.current) {
            return;
        }
        if (wavPlayer.current) {
            const audioBuffer = base64ToInt16Array(data.audio);
            wavPlayer.current.add16BitPCM(audioBuffer, data.itemId);
            
            // Update visualizer data for AI audio
            const frequencies = wavPlayer.current.getFrequencies('voice');
            setVisualizerData(frequencies);
    
            startCheckingAudioPlayback();
        } else {
            console.error('No WavPlayer instance available');
        }
    };

    // Convert base64 that is received from the server to Int16Array
    const base64ToInt16Array = (base64) => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new Int16Array(bytes.buffer);
    };

    const stopAudioPlayback = useCallback(() => {
        if (wavPlayer.current) {
            wavPlayer.current.interrupt().then(() => {
                setAiSpeaking(false);
            }).catch(error => {
                console.error('Failed to interrupt audio playback:', error);
                logException('Failed to interrupt audio playback', { error });
            });
        }
    }, []);

     return {
        backendWsReady,
        aiWsReady,
        isStreaming,
        userSpeaking,
        aiSpeaking,
        isDialing,
        visualizerData,
        transcript,
        startStreaming,
        stopStreaming,
        sendAudioChunk
    };
};

export default useRealtimeVoiceChat;