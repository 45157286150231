import React, { useEffect, useState } from "react";
import { useTestContext } from "../contexts/TestContext";
import { useTooltip } from "../contexts/TooltipContext";
import ActionButton from "./common/ActionButton";
import Button from "./common/Button";
import Chatbox from "./chat/Chatbox";
import KanbanBoard from "./crm/KanbanBoard";
import BackendInterface from "./interfaces/BackendInterface";
import RolePlayInterface from "./interfaces/RolePlayInterface";
import IdePopout from "./coding/IdePopout";
import Popup from "./popup/Popup";
import ScreenCover from "./popup/ScreenCover";
import Tooltip from "./tooltip/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons'; // Warning triangle for the icon of the button

const TestStageComponents = ({}) => {
  const {
    testStage,
    updateTermsStatus,
    acceptTerms,
    activeTest,
    isTestInterrupted,
    currentSection,
    caseCandidateSummary,
    stackItems,
    handleDevEnvConfirm,
    setDevEnvStack,
    devEnvNeeded,
    codeSubmitted,
  } = useTestContext();
  const [showIdePopout, setShowIdePopout] = useState(false); // iframe to vs code
  const { activeTooltips, getTooltipConfig } = useTooltip();

  // General error popup renderer
  const renderErrorPopup = (variant, zIndex) => (
    <Popup variant={variant} isVisible={true} zIndex={zIndex} />
  );

  // Tooltip
  const isIdePopoutTooltipActive = activeTooltips["idePopout"];

  // Function to render the correct interface based on the current section
  const renderInterface = () => {
    if (!currentSection || !currentSection.UI) return null; // If there is no current section or UI defined, return null

    const { interface: interfaceType } = currentSection.UI; // Get the interface type from the current section
    
    switch (interfaceType) {
      case "BackendInterface":
        return <BackendInterface />;
      case "SalesRolePlay":
        return <RolePlayInterface />;
      case "Chatbox":
        return (
          <>
            <Chatbox />
            {devEnvNeeded && codeSubmitted && (
              <>
                {isIdePopoutTooltipActive && (
                  <Tooltip variant="idePopout" />
                )}
                <Button
                  onClick={() => setShowIdePopout(true)}
                  type="tooltip"
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    zIndex: 10
                  }}
                >
                  <ActionButton 
                    icon={faCode} 
                    label="Ver meu código" 
                    showHoverPopout={true}
                    popoutText="Ver meu código"
                    popoutPosition="topRight"
                    textColor="white"
                  />
                </Button>
              </>
            )}
            {showIdePopout && (
              <IdePopout
                onClose={() => setShowIdePopout(false)}
              />
            )}
          </>
        );
      // Add other interfaces as needed
      default:
        return (
          <Chatbox
            style={{
              height: `100vh`,
              width: "100%",
              margin: "0",
            }}
          />
        );
    }
  };

  // Render different components based on the testStage
  switch (testStage) {
    case "initialLoading": // Cover when loading the initial components
      return <ScreenCover isVisible={true} variant="complete" zIndex={11} />;

    case "acceptTerms": // T&Cs popup and checkbox that user needs to click before proceeding
      return (
        <>
          <ScreenCover isVisible={true} variant="blur" zIndex={11} />
          <Popup
            variant="TermsContent"
            isVisible={true}
            onConfirm={acceptTerms}
            onTermsChange={updateTermsStatus}
            zIndex={12}
            showCloseButton={true}
          />
        </>
      );

    case "selectDevEnv": // Popup to choose code language and framework
      return (
        <>
            <ScreenCover isVisible={true} variant="blur" zIndex={11} />
            <Popup
                variant="SelectDevEnv"
                isVisible={true}
                zIndex={12}
                showCloseButton={false}
                handleDevEnvConfirm={handleDevEnvConfirm}
                stackItems={stackItems}
                setDevEnvStack={setDevEnvStack}
                description={caseCandidateSummary}
            />
        </>
      );

    case "introChat": // Chatbox to introduce the user to the test
      return (
        <>
          <Chatbox/>
          {activeTest && (
            <>
              <ScreenCover isVisible={true} variant="complete" zIndex={15} />
              <Popup variant="ActiveTest" isVisible={true} zIndex={16} />
            </>
          )}
        </>
      );

    case "test":
      return (
        <>
          {renderInterface()}
          {isTestInterrupted.interrupted && (
            // If there is an issue with the test, show the error popup, keeping the rendered test behind as it may solve within the retry time
            <>
              <ScreenCover isVisible={true} variant="blur" zIndex={15} />
              {renderErrorPopup("TestInterrupted", 16)}
            </>
          )}
        </>
      );

    case "endOfTest": // Popup to show the user that the test is complete and button to click for feedback
      return (
        <>
          <ScreenCover isVisible={true} variant="complete" zIndex={11} />
          <Popup
            variant="EndTest"
            isVisible={true}
            zIndex={12}
            showCloseButton={true}
            buttonLink="/feedback"
          />
        </>
      );

    // Error handling states
    case "loadFailed":
    case "TestDown":
    case "retrievalFailed":
    case "uniqueUrlFail":
    case "saveFailed":
    case "retrievedTestComplete":
    case "vsCodeLoadFail":
      return (
        <>
          <ScreenCover isVisible={true} variant="complete" zIndex={15} />
          {renderErrorPopup(testStage, 16)}
        </>
      );

    default:
      // Handle unknown stages
      return <ScreenCover isVisible={true} variant="blur" zIndex={10} />;
  }
};

export default TestStageComponents;
