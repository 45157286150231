// Header with logo, test name, timer which can have variants such as intro or test called via props
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { useTestContext } from "../../contexts/TestContext";
import { useTooltip } from "../../contexts/TooltipContext";
import { getCompanyLogoPath } from '../../utils/getCompanyLogo';
import useAuth from "../../hooks/useAuth";
import useEnsureUserData from '../../hooks/useEnsureUserData';
import Tooltip from "../tooltip/Tooltip";
import Logo from "./DegrauLogo";
import Navbar from "./Navbar";
import ProgressBar from "./ProgressBar";
import Timer from "./Timer";
import styles from "./Header.module.css";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [homeLogo, setHomeLogo] = useState(false);
  const [ isNavbarOpen, setIsNavbarOpen ] = useState(false); // Open the navigation menu
  const navigate = useNavigate();
  const location = useLocation();
  const { activeTooltips, getTooltipConfig } = useTooltip();
  const { pdfMode, userInitials, userPermissions } = useAppContext();
  const { testStage, companyDetails, accessibilityMode } = useTestContext();;
  const { accessToken, getUserName } = useAuth(); // Get the access token to see if the user is logged in
  const { updateUserNameAndEmail } = useEnsureUserData(); // Update the user name and email
  const [partnerLogoPath, setPartnerLogoPath] = useState(null); // The path to the partner company logo
  useEffect(() => {
    const logoPath = getCompanyLogoPath(companyDetails); // Get the partner company logo path
    setPartnerLogoPath(logoPath);
  }, [companyDetails]);

  // Show the navbar and make logo clickable if the user is logged in and it is not the interview page
  useEffect(() => {
    const checkToken = async () => {
      // if pathname is /entrevista, return as we don't want to show the navbar in the interview page
      if (location.pathname === "/entrevista" || location.pathname.includes('/entrevista/')) return;
      const token = accessToken(); // check if a logged user
      if (token) {
        // if there is no initials, get the user name and then set the initials
        if (!userInitials) {
          await updateUserNameAndEmail(); // Call the function to update the user name and email
        }
        setShowNavbar(true);
        setHomeLogo(true);
      }
    };

    checkToken(); // Call 

  }, [userInitials, accessToken, getUserName, location, companyDetails]);

  // Check if the current active tooltips are "timer", "timeWarning" or "progress"
  const isProgressTooltipActive = activeTooltips["progress"];
  const isTimerTooltipActive = activeTooltips["timer"];
  const isTimerWarning1TooltipActive = activeTooltips["timerWarning1"];
  const isTimerWarning2TooltipActive = activeTooltips["timerWarning2"];
  const isRetrievedTimerTooltipActive = activeTooltips["retrievedTimer"];
  const isFollowUpTimerTooltipActive = activeTooltips["followUpTimer"];
  const isAdedTimeTooltipActive = activeTooltips["addedTime"];

  // Get the configurations for the tooltips
  const progressTooltipConfig = getTooltipConfig("progress");
  const timerTooltipConfig = getTooltipConfig("timer");
  const timerWarning1TooltipConfig = getTooltipConfig("timerWarning1");
  const timerWarning2TooltipConfig = getTooltipConfig("timerWarning2");
  const retrievedTimerWarningTooltipConfig = getTooltipConfig("retrievedTimer");
  const followUpTimerWarningTooltipConfig = getTooltipConfig("followUpTimer");
  const addedTimeTooltipConfig = getTooltipConfig("addedTime");

  // NAVIGATION

  // Toggle the navbar visibility
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // Function to handle clicking on the Degrau logo
  const handleDegrauLogoClick = () => {
    if (homeLogo) {
      navigate("/login");
    }
  };

  return (
    <header className={styles.header} role="banner">
      {isNavbarOpen && (
        <Navbar isOpen={isNavbarOpen} userPermissions={userPermissions} toggleNavbar={toggleNavbar} />
      )}
      {!pdfMode && (
        <div className={styles.headerInnerContainer}>
          <div className={styles.firstArea}>
            {/* Degrau Logo */}
            <div
              className={homeLogo ? styles.clickableDegrauLogoHeader : styles.degrauLogoHeader} // Conditional styling
              onClick={handleDegrauLogoClick}
              role={homeLogo ? "button" : undefined} // Set role for accessibility if clickable
              tabIndex={homeLogo ? 0 : undefined} // Make focusable if clickable
              aria-label={homeLogo ? "Go to login page" : "Degrau Logo"} // ARIA label
              onKeyDown={(e) => {
                if (homeLogo && (e.key === "Enter" || e.key === " ")) {
                  handleDegrauLogoClick();
                }
              }} // Keyboard accessibility
            >
              <Logo className={styles.degrauLogoHeader} />
            </div>
          </div>

          {/* Tooltips */}
          {isProgressTooltipActive && progressTooltipConfig && (
            <Tooltip variant="progress" />
          )}
          {isTimerTooltipActive && timerTooltipConfig && (
            <Tooltip variant="timer" />
          )}
          {isTimerWarning1TooltipActive && timerWarning1TooltipConfig && (
            <Tooltip variant="timerWarning1" />
          )}
          {isTimerWarning2TooltipActive && timerWarning2TooltipConfig && (
            <Tooltip variant="timerWarning2" />
          )}
          {isRetrievedTimerTooltipActive &&
            retrievedTimerWarningTooltipConfig && (
              <Tooltip variant="retrievedTimer" />
            )}
          {isFollowUpTimerTooltipActive &&
            followUpTimerWarningTooltipConfig && (
              <Tooltip variant="followUpTimer" />
            )}
          {isAdedTimeTooltipActive && addedTimeTooltipConfig && (
            <Tooltip variant="addedTime" />
          )}

          {(testStage === "test" ||
            testStage === "introChat" ||
            testStage === "acceptTerms") && <ProgressBar />}
          {/* Timer - dont show in accessibility mode */}
          {testStage === "test" && !accessibilityMode && (
            <>
              <Timer
                onTimeUp={() => {
                  /* Handle time up logic */
                }}
              />
            </>
          )}

          <div className={styles.lastArea}>
            {/* Partner Company Logo */}
            {partnerLogoPath && (
              <img
                src={partnerLogoPath}
                alt="Logotipo da empresa de contratação"
                className={styles.partnerLogo}
                aria-label="Logotipo da empresa de contratação"
              />
            )}
              
            {/* Navbar Button */}
            {showNavbar && (
              <button
                onClick={toggleNavbar}
                aria-label="Menu de usuário"
                className={styles.navbarButton}
              >
                {userInitials}
              </button>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
