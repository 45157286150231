//Interface for backend dev test
import React, { useEffect, useState, useContext, useRef } from 'react';
import ReactMarkdown from 'react-markdown'; // For styling the instructions
import remarkGfm from 'remark-gfm'; // For enabling GFM in the instructions for tables
import { AppContext } from '../../contexts/AppContext';
import { useTooltip } from '../../contexts/TooltipContext'; 
import { useTestContext } from '../../contexts/TestContext';
import { useCodeContext } from '../../contexts/CodeContext';
import Tooltip from '../tooltip/Tooltip'; 
import Resizer from '../common/Resizer';  
import FadeLoader from '../common/FadeLoader';
import Chatbox from '../chat/Chatbox';
import Popup from '../popup/Popup';
import ScreenCover from '../popup/ScreenCover';
import IDE from '../coding/IDE';  
import NewIde from '../coding/NewIde';
import JupyterIde from '../coding/JupyterIde';
import SqlInterface from '../coding/SqlInterface'; 
import styles from './BackendInterface.module.css';
import { Tabs, Tab } from '../common/Tabs';
import useResize from '../../hooks/useResize';

const BackendInterface = ({ role }) => {
    const { caseInstructions, 
        currentSection, 
        codeSubmitted, 
        setCodeSubmitted, 
        isTestStarted, 
        setFinalCode,
        devEnvNeeded,
        vsCodeIp,
    } = useTestContext(); // Load in the instructions to show to the user
    const { isResizing, startResizing } = useResize();
    // log isResizing
    const [instructionsText, setInstructionsText] = useState("Carregando instruções...");
    const { retrievedCodeSubmitted, retrievedCode } = useContext(AppContext); // If codeSubmitted needs to be set when a session is restored from a returning user
    const { activeTooltips, getTooltipConfig } = useTooltip(); // Use the Tooltip context
    const isTestInstructionsTooltipActive = activeTooltips['testInstructions'];
    const testInstructionsTooltipConfig = getTooltipConfig('testInstructions');
    const [ tabsPresent, setTabsPresent ] = useState(false); // Identify if there is tabs in order to adjust heights

    // Update codeSubmitted and the finalCode if a session is restored (AppContext will update retrievedMessages with the messages saved in the DB for that session)
    useEffect(() => {
        if (retrievedCodeSubmitted) {
            setFinalCode(retrievedCode); // Set the finalCode to the retrievedCode from the restored session
            setCodeSubmitted(true); // Ensure codeSubmitted is updated to reflect the restored session status
        }
    }, [retrievedCodeSubmitted, retrievedCode]);
    
    // Get the case instructions for the current section
    useEffect(() => {
        if (caseInstructions && currentSection && caseInstructions[currentSection.sectionName]?.instructions) {
            setInstructionsText(caseInstructions[currentSection.sectionName].instructions);
        } else {
            setInstructionsText(""); // Use blank if there are no instructions for the current section
        }
    }, [caseInstructions, currentSection]);

    // Determine the presence of tabs based on the current section
    useEffect(() => {
        if (currentSection && currentSection.UI) {
            const { IDEComponent = {}, SQLComponent = {}, JupyterComponent = {}, NewIDEComponent = {} } = currentSection.UI;
            const componentsCount = [IDEComponent.enabled, SQLComponent.enabled, JupyterComponent.enabled, NewIDEComponent.enabled].filter(Boolean).length;
            setTabsPresent(componentsCount > 1);
        } else {
            setTabsPresent(false);
        }
    }, [currentSection]);

    // Render the content based on the current section
    const renderContent = () => {
        if (!currentSection || !currentSection.UI) return null;
    
        const { IDEComponent = null, SQLComponent = null, NewIDEComponent = null, JupyterComponent = null } = currentSection.UI;

        if (tabsPresent) {
            const tabs = [];

            if (IDEComponent?.enabled) {
                tabs.push(
                    <Tab label="Editor de Código" key="editor">
                        <IDE tabsPresent={tabsPresent} />
                    </Tab>
                );
            }

            if (SQLComponent?.enabled) {
                tabs.push(
                    <Tab label="Terminal SQL" key="terminal">
                        <SqlInterface />
                    </Tab>
                );
            }

            if (NewIDEComponent?.enabled) {
                tabs.push(
                    <Tab label="VS Code" key="vscode">
                        <NewIde />
                    </Tab>
                );
            }

            if (JupyterComponent?.enabled) {
                console.log('JupyterComponent', JupyterComponent);
                tabs.push(
                    <Tab label="Jupyter" key="jupyter">
                        <JupyterIde />
                    </Tab>
                );
            }
            return (
                <>
                    <Tabs>
                        {tabs}
                    </Tabs>
                </>
            );
        }

        if (IDEComponent?.enabled) {
            return (
                <>
                    <IDE 
                        tabsPresent={tabsPresent}
                    />
                </>
            );
        }

        if (SQLComponent?.enabled) {
            return (
                <>
                    <SqlInterface />
                </>
            );
        }

        if (NewIDEComponent?.enabled) {
            return (
                <>
                    <NewIde />
                </>
            );
        }

        if (JupyterComponent?.enabled) {
            return (
                <>
                    <JupyterIde />
                </>
            );
        }
        // Fallback if no specific UI components are specified
        return (
            <>
                {/* Default interface content */}
            </>
        );
    };

    if (devEnvNeeded && !vsCodeIp) {
        return (
            <div className={styles.devEnvCover}>
                <FadeLoader 
                    text="Estamos configurando seu ambiente de desenvolvimento, isso pode demorar alguns minutos..."
                    height="100%"
                    width="100%"
                    zIndex="11"
                    textColor="#fff"
                />
            </div>
        );
    }

    return (
        <div className={styles.backendInterface}>
            {isResizing && (<div className={styles.resizeOverlay}></div> )} {/* Show overlay when resizing */}
            {!isTestStarted && (
                <div className={styles.backendInterfaceCover}></div>
            )}

            {/* Conditionally render Instructions container */}
                <div id="instructionsContainer" className={styles.instructionsContainer} >
                    <div 
                        className={styles.fullInstructions} 
                        style={{ filter: isTestStarted ? 'none' : 'blur(3px)' }}
                    >
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {instructionsText}
                        </ReactMarkdown>
                    </div>
                    {isTestInstructionsTooltipActive && testInstructionsTooltipConfig && (
                        <Tooltip variant="testInstructions" />
                    )}
                </div>
            {/* Conditionally render Horizontal Resizer */}
                <div className={styles.horizontalResizerContainer}>
                    <Resizer type="horizontal" onMouseDown={(e) => startResizing(e, true)} />
                </div>

            {/* Chatbox component (always rendered) */}
            <div className={styles.backendChatbox}>
                <Chatbox />
            </div>

            {/* Conditionally render Vertical Resizer */}
                <div className={styles.verticalResizerContainer}>
                    <Resizer type="vertical" onMouseDown={(e) => startResizing(e, false)} />
                </div>

            {/* Conditionally render content based on role */}
                <div className={styles.devContent}>
                    {renderContent()}
                </div>
        </div>
    );
};

export default BackendInterface;